<mat-card>
  <mat-card-header>
    <mat-card-title class="smv-large-card-title" i18n="Title|Farm Informations">Informations de l'exploitation</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <!-- Form Section -->
    <form [formGroup]="form">
      <!-- Nom de l'exploitation -->
      <smv-form-field-wrapper formControlName="name">
        <span mat-label i18n="Label|Farm name">Nom de l'exploitation</span>
      </smv-form-field-wrapper>

      <!-- Numéro SIRET -->
      <smv-form-field-wrapper formControlName="siret">
        <span mat-label i18n="Label|Siret">Numéro SIRET</span>
      </smv-form-field-wrapper>

      <!-- Adresse -->
      <smv-form-field-wrapper formControlName="address">
        <span mat-label i18n="Label|Address">Adresse</span>
      </smv-form-field-wrapper>

      <div class="zipcode-city">
        <!-- Code postal -->
        <smv-form-field-wrapper formControlName="zipCode" class="zip-code">
          <span mat-label i18n="Label|Zip Code">Code postal</span>
        </smv-form-field-wrapper>

        <!-- Ville -->
        <smv-form-field-wrapper formControlName="city">
          <span mat-label i18n="Label|City">Ville</span>
        </smv-form-field-wrapper>
      </div>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <!-- Save Button -->
    <button mat-raised-button [disabled]="form.invalid" (click)="save()" color="primary" i18n="Button|Save">
      Enregistrer
    </button>
  </mat-card-actions>
</mat-card>
