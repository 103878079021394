import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'smv-champ-propre-legal-notices',
  standalone: true,
  imports: [CommonModule, MatDividerModule],
  templateUrl: './champ-propre-legal-notices.component.html',
  styleUrls: ['./champ-propre-legal-notices.component.scss'],
})
export class ChampPropreLegalNoticesComponent {
  constructor() {}
}
