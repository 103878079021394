<section>
  <h2 i18n="Legal Mention">Date de la version : 15/01/2024</h2>
</section>

<section>
  <h2 i18n="Legal Mention">1. À PROPOS DE CES TERMES ET CONDITIONS</h2>
  <p i18n="Legal Mention">
    Avant d'utiliser l'un de nos Services, veuillez prendre quelques minutes pour lire attentivement :
    <li>(i) les présentes conditions générales ;</li>
    <li>
      (ii) notre politique de confidentialité ("<span class="bold">Politique de confidentialité</span>") - elle contient
      de plus amples informations sur la manière dont nous traitons les données personnelles en tant que responsable du
      traitement des données et sur les droits dont vous disposez ; et
    </li>
    <li>(iii) toutes les conditions de l'offre proposée.</li>
    Les présentes conditions générales et les conditions de toute offre proposée constituent un accord juridique
    contraignant entre vous et nous, concernant l'utilisation de nos services. Nous les désignons par le terme " Accord
    ". En cas de conflit ou d'incohérence entre les présentes conditions générales et les conditions de l'offre
    proposée, les conditions générales de l'offre proposée (offre test ou commerciale) prévaudront.
  </p>
  <p i18n="Legal Mention">
    Pour pouvoir utiliser l'un des Services, vous devez d'abord : (i) créer ou détenir un compte ; et (ii) lire et
    accepter le présent Accord.

    <span class="bold">
      Il se peut que certains Services (ou certaines de leurs caractéristiques) ne soient pas disponibles dans votre
      pays, qu'ils ne soient disponibles que pour certains utilisateurs, qu'ils ne soient disponibles que pour une
      période limitée ou qu'ils soient soumis à des conditions supplémentaires, comme indiqué dans les conditions de
      l’offre proposée applicable.</span
    >
    Veuillez contacter votre interlocuteur habituel si vous avez des questions. Le présent Accord fait usage de certains
    termes définis (que nous avons mis en majuscules). Ces termes sont définis tout au long de l'accord, ou dans la
    section 20 (Termes définis).
  </p>
  <p i18n="Legal Mention" class="bold">
    Lorsque vous utilisez les Services dans le cadre d'un service que vous fournissez à vos clients finaux, vous vous
    engagez également à vous assurer que, dans la mesure où cela est pertinent, vos clients finaux respectent le présent
    accord.
  </p>
</section>

<section>
  <h2 i18n="Legal Mention">2. QUI NOUS SOMMES</h2>
  Nous sommes "<span class="bold">Syngenta</span>" - Syngenta France SA, 1228 chemin de l'Hobbit, 31790 Saint-Sauveur,
  France. Dans le présent Accord, nous utilisons les termes <span class="bold">" nous "</span>,
  <span class="bold">" notre "</span> et <span class="bold">" nos "</span> pour désigner Syngenta et les sociétés du
  groupe Syngenta.
</section>

<section>
  <h2 i18n="Legal Mention">3. QUI VOUS ÊTES</h2>
  <p i18n="Legal Mention">
    En utilisant nos Services et en acceptant les présentes conditions générales, vous reconnaissez que vous êtes un
    utilisateur professionnel, agissant au nom et pour les besoins de votre entreprise, qu'il s'agisse d'une société,
    d'un entrepreneur individuel ou de toute autre forme d'entité commerciale (votre
    <span class="bold">"Entreprise"</span>). Nous ne proposons pas nos Services aux consommateurs et/ou particuliers.
  </p>
  <p i18n="Legal Mention">
    Sauf indication contraire, dans le présent Accord, les
    <span class="bold">termes "vous"</span> et <span class="bold">"votre"</span> désignent à la fois la personne
    (utilisateur) qui a créé un compte pour utiliser les Services et l'entreprise pour le compte de laquelle le compte a
    été créé. Veuillez noter que l'Entreprise sera le contrôleur de données en ce qui concerne les données personnelles
    qui nous sont divulguées lors de l'utilisation des Services - veuillez consulter la section 16.
  </p>
</section>

<section>
  <h2 i18n="Legal Mention">4. MODIFICATIONS DU PRÉSENT ACCORD</h2>
  <p i18n="Legal Mention">
    nous réservons le droit d'apporter des modifications au présent accord ou à toute partie de celui-ci (y compris les
    présentes conditions générales et les conditions des offres proposées) à tout moment, notamment lorsque nous lançons
    des Services nouveaux ou mis à jour.
  </p>
  <p i18n="Legal Mention">Si vous n'acceptez pas ces mises à jour, l'accès aux Services peut vous être refusé.</p>
</section>

<section>
  <h2 i18n="Legal Mention">5. COMMENT FONCTIONNENT LES SERVICES</h2>
  <p i18n="Legal Mention">
    Les Services utilisent Vos Données d’entrée (tels que définis ci-dessous à l'article 19) et les traitent, y compris
    par le biais de l'extrapolation, de la combinaison avec des données et des informations provenant d'autres sources
    et de l'utilisation d'algorithmes et de modèles statistiques développés par ou pour Syngenta, afin de produire les
    Sorties.
  </p>
  <p i18n="Legal Mention">
    Les Résultats sont destinés à être utilisés pour soutenir et informer les décisions agronomiques que vous prenez
    indépendamment ou, lorsque vous utilisez les Services dans le cadre d'un service que vous fournissez à vos Clients
    finaux, par vous et vos Clients finaux. Les Services, y compris les Résultats, ne doivent pas être utilisés à
    d'autres fins.
  </p>
  <p i18n="Legal Mention">
    <span class="bold">VEUILLEZ NOTER -</span> Les Sorties fournies par les Services dépendent de certains facteurs que
    nous ne pouvons pas contrôler :
  </p>
  <ul class="bullet-list">
    <li i18n="Legal Mention">
      <span class="bold">Algorithmes</span> - L'aide à la décision agronomique est générée par des algorithmes très
      complexes qui ne peuvent pas représenter toutes les particularités du cas individuel. Nous cherchons à améliorer
      constamment notre approche de l'aide à la décision. Il est néanmoins essentiel que vous utilisiez votre jugement
      professionnel quant à la sécurité et à l'adéquation de l'une ou l'autre des Sorties.
    </li>
    <li i18n="Legal Mention">
      <span class="bold">Vos Données d'entrée -</span> La qualité, l'exactitude et l'actualité des données que vous
      saisissez auront une incidence sur les Sorties.
    </li>
    <li i18n="Legal Mention">
      <span class="bold">Données de tiers -</span> bien que nous fassions nos meilleurs efforts pour garantir la qualité
      et l'exactitude des données de partenaires tiers, certaines données de tiers sont mises à disposition pour être
      utilisées sans garantie qu'elles soient correctes ou exemptes d'erreurs.
    </li>
    <li i18n="Legal Mention">
      <span class="bold">Les données sur les produits agricoles peuvent ne pas être entièrement à jour -</span>
      nous faisons nos meilleurs efforts pour nous assurer que les données sur les produits agricoles (par exemple, les
      informations sur les variétés de semences, les produits de protection des cultures et les équipements agricoles de
      Syngenta et de tiers) sont à jour lorsque nous publions un Service. Des données nouvelles ou actualisées sur les
      produits agricoles peuvent être publiées de temps à autre, mais elles ne seront incorporées dans nos Services que
      périodiquement, lorsque nous publierons une mise à jour. Par conséquent, vous ne devez pas vous fier aux Services
      comme étant une source de données sur les produits agricoles qui fait autorité et qui est à jour.
    </li>
    <li i18n="Legal Mention">
      <span class="bold">Conditions réelles -</span> il se peut que vous n'obteniez pas les résultats souhaités prédits
      par les Services, car les conditions réelles, comme la météo, sont variables et peuvent être différentes des
      conditions dans lesquelles les équipements ont été testés ou pour lesquelles les algorithmes et les modèles
      statistiques ont été développés.
    </li>
    <li i18n="Legal Mention">
      <span class="bold">La météo -</span> en raison de la nature des prévisions météorologiques, les prévisions
      fournies par nos partenaires météorologiques ne seront pas entièrement exactes.
    </li>
    <li i18n="Legal Mention">
      <span class="bold">Les profils ou paramètres personnalisables</span>
      que vous avez spécifiés dans les Services, par exemple les seuils de risque.
    </li>
  </ul>
  <p i18n="Legal Mention" class="bold">
    VEUILLEZ NOTER QUE Syngenta ne fournit aucune garantie que l'utilisation des Services produira les résultats
    agricoles souhaités.
  </p>

  <p i18n="Legal Mention" class="bold">
    Les résultats ne doivent pas être utilisés comme la seule base pour prendre des décisions agronomiques et sont
    donnés à titre indicatif.
  </p>
  <p i18n="Legal Mention">
    <span class="bold">Informations sur les Services Syngenta.</span>
    Toute information contenue ou référencée dans les Services concernant Syngenta et ses semences ou produits et
    services de protection des cultures est fournie pour soutenir et informer vos décisions agronomiques indépendantes.
    Pour toute information complémentaire ou toute question concernant nos variétés de semences, nos produits de
    protection des cultures ou nos services, veuillez nous contacter directement. Si vous avez l'intention d'utiliser un
    produit phytosanitaire ou une semence (qu'il s'agisse du produit de Syngenta ou d'un tiers), vous devez lire et
    suivre l'étiquette qui accompagne ce produit et vous conformer à toutes les lois et réglementations applicables dans
    votre pays concernant l'utilisation de ce produit. Avant d'utiliser un produit, assurez-vous qu'il est homologué
    pour être utilisé dans votre pays.
  </p>
  <p i18n="Legal Mention" class="bold">
    En particulier, vous convenez qu'il vous incombe d'inspecter les parcelles et d'exercer votre jugement pour décider
    de suivre les orientations d'aide à la décision agronomique ou d'autres Sorties, et de mettre en œuvre des pratiques
    agronomiques conformément aux bonnes pratiques agricoles, aux orientations en matière de stewardship et à toute loi
    et réglementation locale applicable. Nous attirons votre attention sur les sections 17 ("Disclaimer") et 18
    ("Limitation de responsabilité") ci-dessous.
  </p>
</section>

<section>
  <h2 i18n="Legal Mention">6. MODIFICATIONS DES SERVICES</h2>
  <p i18n="Legal Mention">
    Nous nous réservons le droit de modifier les Services (y compris leurs caractéristiques et autres contenus et
    fonctionnalités) de temps à autre, ce qui peut nécessiter des modifications de ce Contrat, selon le processus décrit
    ci-dessus. Sauf dans la mesure où cela est expressément prévu dans ce Contrat, Syngenta ne sera pas responsable
    envers vous de quelque manière que ce soit des conséquences possibles des changements apportés aux Services ou à ce
    Contrat.
  </p>
</section>

<section>
  <h2 i18n="Legal Mention">7. SÉCURITÉ DU MOT DE PASSE</h2>
  <p i18n="Legal Mention">
    Il vous incombe de veiller à la sécurité du mot de passe de votre compte. Vous devez notamment choisir un mot de
    passe complexe qui n'est pas utilisé pour d'autres comptes, ne pas partager votre mot de passe avec une autre
    personne et ne pas lui donner accès à votre compte. Si vous oubliez votre mot de passe, vous pouvez le
    réinitialiser.
  </p>
</section>

<section>
  <h2 i18n="Legal Mention">8. DROITS DE PROPRIÉTÉ</h2>
  <p i18n="Legal Mention">
    Les Services contiennent des Marques et des Contenus qui sont la propriété de Syngenta ou de ses concédants et qui
    sont protégés par la propriété intellectuelle et d'autres droits et lois. Syngenta et ses concédants de licence
    conservent la propriété exclusive des Services, de leur Contenu et des Marques commerciales. Vous n'êtes pas
    autorisé à reproduire, afficher ou utiliser de quelque manière que ce soit toute Marque commerciale, sauf dans les
    cas prévus ci-dessous.
  </p>
  <p i18n="Legal Mention" class="bold">
    Lorsque vous utilisez les Services dans le cadre d'un service que vous fournissez à vos clients finaux, vous ne
    devez pas vous présenter comme le propriétaire ou le concédant exclusif des Services, de leur Contenu et des Marques
    commerciales, ni les faire passer pour les vôtres lorsque vous traitez avec vos Clients finaux.
  </p>
</section>
<section>
  <h2 i18n="Legal Mention">9. VOTRE LICENCE D'UTILISATION DES SERVICES</h2>
  <p i18n="Legal Mention">
    Vous disposez d'un droit non exclusif, non transférable et non sous-licenciable d'utiliser les Services et de
    télécharger, exécuter, stocker, imprimer, copier et partager les résultats des Services pendant la durée de validité
    du présent contrat, à condition que vous n'utilisiez les résultats qu'au sein de votre entreprise (ou, lorsque vous
    utilisez les Services dans le cadre d'un service que vous fournissez à vos clients finaux, au sein des entreprises
    de vos clients finaux) d'une manière conforme au présent contrat (en particulier l'article 5) et vous ne modifiez
    pas les Services de quelque manière que ce soit (par exemple, en supprimant ou en modifiant les Marques ou autres
    avis). Vous ne pouvez pas vendre, louer, accorder une licence ou transférer de quelque manière que ce soit les
    Services à des tiers ou générer des Services pour des tiers (sauf, lorsque vous utilisez les Services dans le cadre
    d'un service que vous fournissez à vos Clients finaux, pour les Clients finaux). Toute tierce partie souhaitant
    utiliser les Services doit créer son propre Compte.
  </p>
  <p i18n="Legal Mention">
    À l'exception de ce qui est indiqué ci-dessus, aucun autre droit sur les Services ou les Sorties ou sur tout autre
    contenu ne vous est transféré. Par exemple (sans limitation), vous ne pouvez pas faire ce qui suit sans avoir obtenu
    au préalable l'autorisation écrite de Syngenta : publier, afficher ou transférer les Services ou créer un lien vers
    ceux-ci à partir de tout autre site accessible au public.
  </p>
</section>
<section>
  <h2 i18n="Legal Mention">10. Garantie LIMITÉE</h2>
  <p i18n="Legal Mention">
    Nous garantissons que, lorsqu'ils sont utilisés correctement et conformément au présent contrat et à toute autre
    instruction émise avec ou dans le cadre des Services, les Services fonctionneront essentiellement conformément au
    présent contrat. Lorsque vous utilisez les Services dans le cadre d'un service que vous fournissez à vos clients
    finaux, vous reconnaissez que cette garantie est fournie à votre entreprise uniquement et ne peut être invoquée par
    aucun client final.
  </p>
</section>
<section>
  <h2 i18n="Legal Mention">11. VOS OBLIGATIONS ENVERS SYNGENTA</h2>
  <p i18n="Legal Mention">
    Vous déclarez et garantissez que :
    <li>
      (i) Vos Données d’entrée (telles que définies ci-dessous à l'article 19 ) ont été obtenues conformément à toutes
      les lois applicables et continuent de l'être, et vous disposez des droits requis pour saisir ou télécharger vos
      données dans les Services et pour accorder la licence décrite ci-dessous en vertu de l'article 12 (" Votre
      concession de licence à Syngenta ") ;
    </li>
    <li>
      (ii) Lorsque Vos Données d’entrée comprennent des données personnelles relatives à un individu autre que vous-même
      (par exemple, le nom, l'adresse électronique, les données de géolocalisation de l'appareil, l'imagerie), vous (en
      tant entreprise concernée) avez établi une base légale valide en vertu des lois applicables sur la protection des
      données pour traiter ces données à caractère personnel, et vous vous conformerez par ailleurs à vos obligations en
      tant que responsable du traitement de ces données (voir la section 16), y compris en matière de transparence ;
    </li>
    <li>
      (iii) Vos Données d’entrée ne portent pas atteinte à la propriété intellectuelle, à la vie privée ou à d'autres
      droits d'un tiers et Syngenta n'aura pas besoin d'obtenir des licences ou de payer des redevances à un tiers ; et
    </li>
    <li>(iv) Vous garderez les informations de votre compte (y compris les détails de votre) à jour à tout moment.</li>
  </p>
</section>
<section>
  <h2 i18n="Legal Mention">12. VOTRE CONCESSION DE LICENCE À SYNGENTA</h2>
  <p i18n="Legal Mention">
    Les Services peuvent nécessiter Vos Données d’entrée (tels que définis ci-dessous à l'article 19). Par la présente,
    vous nous accordez un droit non exclusif, mondial, transférable, libre de redevance, pouvant faire l'objet d'une
    sous-licence et perpétuel d'utiliser Vos Données d’entrée afin de produire les Sorties et de rendre possible votre
    utilisation des Services, ainsi que pour améliorer nos Services, développer de nouveaux Services, améliorer nos
    modèles et tout autre objectif que nous déterminons, sous réserve des conditions du présent accord. Il se peut que
    vous ne puissiez pas utiliser certaines caractéristiques des Services si vous ne fournissez pas les informations
    requises. Entre vous et Syngenta, vous êtes propriétaire de Vos Données d’entrée.
  </p>
  <p i18n="Legal Mention" class="bold">
    Lorsque vous utilisez les Services dans le cadre d'un service que vous fournissez à vos clients finaux, vous
    reconnaissez qu'il est de votre responsabilité d'obtenir de vos clients finaux tous les droits, autorisations ou
    licences nécessaires pour nous accorder la licence prévue par le présent article 12.
  </p>
</section>
<section>
  <h2 i18n="Legal Mention">13. NOTRE UTILISATION DES DONNÉES ANONYMISEES</h2>
  <p i18n="Legal Mention">
    Dans la mesure où Vos Données d’entrée comprennent des données à caractère personnel, nous n'utiliserons ces données
    à caractère personnel que conformément à notre Politique de confidentialité et à l'article 15. Nonobstant ce qui
    précède, vous reconnaissez que nous pouvons agréger ou anonymiser les données à caractère personnel contenues dans
    Vos Contributions afin qu'elles ne constituent plus des données à caractère personnel mais des données anonymisées
    (<span class="bold">"Données anonymisées"</span>).
  </p>
</section>
<section>
  <h2 i18n="Legal Mention">14. RESTRICTIONS CONCERNANT VOTRE UTILISATION DES SERVICES</h2>
  <p i18n="Legal Mention">
    Vous acceptez de respecter la loi applicable lorsque vous utilisez les Services. Vous acceptez de ne pas utiliser de
    système automatisé pour accéder aux Services, de ne pas faciliter l'accès non autorisé d'une personne aux Services
    et de ne pas modifier la fonctionnalité ou la disponibilité des Services. Vous acceptez de ne pas décompiler,
    inverser la compilation, désassembler, faire de l'ingénierie inverse ou réduire de toute autre manière à une forme
    perceptible par l'homme tout ou partie des Services ou de leur Contenu, ni de tenter de dériver le code source ou de
    créer des œuvres dérivées.
  </p>
</section>
<section>
  <h2 i18n="Legal Mention">15. CONDITIONS DE TRAITEMENT DES DONNÉES</h2>
  <p i18n="Legal Mention" class="bold">
    Vous comprenez que toutes les données à caractère personnel comprises dans Vos Données d’entrée que nous utilisons
    afin de produire les Sorties sont traitées par nous, en tant que processeur de données, pour votre compte (en tant
    qu'Entreprise concernée) en tant que contrôleur de données.
  </p>
  <p i18n="Legal Mention">
    En ce qui concerne ces données à caractère personnel, nous nous conformerons, en tant que processeur de données, aux
    dispositions de l'article 28(3) du Règlement général sur la protection des données ("<span class="bold">
      RGPD "</span
    >), qui sont par les présentes incorporées par référence au présent Contrat. Aux fins de l'article 28(2) du RGPD,
    nous sommes généralement autorisés à désigner des sous-traitants secondaires, à condition que ces désignations
    soient conformes aux dispositions de l'article 28(4) du RGPD. Aux fins de l'article 28(3)(h) du RGPD, vous
    reconnaissez que votre droit à un audit ou à une inspection sera en premier lieu satisfait par la vue d'un rapport
    d'audit interne. Si un tel rapport ne répond pas à vos préoccupations, vous aurez le droit, à vos frais, de ne pas
    effectuer plus d'un audit (par vous-même ou par un auditeur mandaté) par année civile.
  </p>
  <p i18n="Legal Mention">
    Vous comprenez en outre que les obligations ci-dessus ne s'appliquent qu'aux données à caractère personnel que nous
    traitons en votre nom en tant que sous-traitant, et non aux données à caractère personnel que nous traitons pour nos
    propres besoins en tant que contrôleur de données. Veuillez-vous référer à notre politique de confidentialité pour
    plus d'informations sur notre traitement des données personnelles en tant que contrôleur de données. Lorsque vous
    utilisez les Services dans le cadre d'un service que vous fournissez à vos Clients finaux, vous devez vous assurer
    que chaque Client final, ainsi que chaque personne concernée associée à un Client final dont les données
    personnelles nous sont fournies, a été dirigé vers notre Politique de confidentialité. En tant que responsable du
    traitement des données, vous reconnaissez qu'il vous incombe de garantir la légalité du traitement des données à
    caractère personnel, ce qui inclut l'obligation de fournir un avis de confidentialité des données aux personnes
    concernées dont les données à caractère personnel sont traitées dans le cadre de la production des Sorties.
  </p>
</section>
<section>
  <h2 i18n="Legal Mention">16. CLAUSE DE NON-RESPONSABILITÉ</h2>
  <p i18n="Legal Mention">Les Services et leur contenu sont fournis " tels quels ", " tels que disponibles ".</p>
  <p i18n="Legal Mention">
    Nous ne garantissons pas que les Services seront toujours disponibles. Bien que nous nous efforcions de minimiser
    les perturbations et de vous informer à l'avance des interruptions prévues, nous nous réservons le droit de fermer
    les Services sans préavis. Dans toute la mesure permise par la loi applicable, et à l'exception de ce qui est
    expressément établi dans ce Contrat, Syngenta et nos fournisseurs de services et concédants de licence respectifs
    déclinent toute représentation et garantie de toute sorte, y compris : (a) toutes les garanties de qualité
    marchande, d'adéquation à un usage particulier, de titre et d'absence de contrefaçon en ce qui concerne les Services
    et leur Contenu ; (b) toutes les garanties relatives aux retards, interruptions, erreurs ou omissions dans le
    fonctionnement des Services ou de toute partie des Services ; (c) toutes les garanties relatives à la transmission
    ou à la livraison des Services ou à leur disponibilité à tout moment ou endroit particulier ; (d) toutes les
    garanties relatives à la disponibilité de vos entrées - vous devez conserver des copies de celles-ci et ne pas vous
    appuyer sur nos Services comme principal moyen de les stocker ; (e) toutes les garanties relatives à la sécurité des
    Services ou à l'absence de virus, de vers informatiques ou d'autres codes pouvant présenter des propriétés
    contaminantes ou destructrices ; et (f) toutes les garanties relatives à l'utilisation, la validité, l'exactitude,
    l'actualité, l'exhaustivité, la pertinence, la fiabilité et la disponibilité des Services et du contenu. Nos
    Services peuvent contenir des hyperliens vers des sites Web qui ne sont pas exploités par nous ou par d'autres
    sociétés du groupe Syngenta. Ces hyperliens sont fournis à titre de référence et de commodité uniquement, et
    n'impliquent aucune approbation du matériel sur ces sites Web tiers ni aucune association avec leurs opérateurs.
    Syngenta ne contrôle pas ces sites Web et n'est pas responsable de leur contenu. Vous accédez à ces sites Web et les
    utilisez uniquement à vos propres risques.
  </p>
</section>
<section>
  <h2 i18n="Legal Mention">17. LIMITATION DE LA RESPONSABILITÉ</h2>
  <p i18n="Legal Mention" class="bold">
    Vous utilisez les Services et leur Contenu à vos propres risques. Dans toute la mesure permise par la loi
    applicable, Syngenta et nos fournisseurs de services et concédants de licence respectifs ne peuvent être tenus
    responsables envers vous (que ce soit par contrat, négligence, délit, responsabilité stricte, violation d'une
    obligation légale ou autre) pour toute perte ou tout dommage découlant de ou en relation avec : (a) votre accès aux
    Services ou à leur contenu, leur utilisation ou votre incapacité à les utiliser, ou la confiance que vous accordez
    au contenu ; (b) les éventuelles erreurs ou omissions dans le contenu ; ou (c) les informations sur le compte que
    vous nous fournissez qui sont incorrectes, incomplètes ou obsolètes. Cette exclusion de responsabilité s'applique à
    toutes les pertes et à tous les dommages de quelque nature que ce soit, qu'ils soient directs ou indirects, y
    compris, sans s'y limiter, l'interruption des activités, la perte ou l'endommagement des données, des cultures ou du
    fonds de commerce, et la perte de revenus ou de bénéfices, même si Syngenta connaissait ou aurait dû connaître la
    possibilité d'une telle perte.
  </p>
  <p i18n="Legal Mention" class="bold">
    À l'exception des pertes mentionnées ci-dessus (pour lesquelles nous ne sommes pas responsables), la responsabilité
    globale de Syngenta et de nos fournisseurs de services et concédants de licence respectifs ne dépassera pas le
    montant le plus élevé entre (i) ce que vous avez payé pour le Service applicable au cours des douze mois précédant
    immédiatement l'événement donnant lieu à une réclamation ; ou (ii) cent euros (100,00 €) ou l'équivalent en monnaie
    locale. Lorsque vous utilisez les Services dans le cadre d'un service que vous fournissez à vos Clients finaux, vous
    reconnaissez expressément que : (i) vous êtes directement responsable envers nous des actes et omissions de vos
    Clients finaux (y compris tout représentant de vos Clients finaux qui utilise les Services) ; et (ii) Syngenta
    n'aura aucune responsabilité envers vos Clients finaux pour toute violation par nous ou par vous de ce Contrat. Nous
    vous conseillons de mettre en place un contrat solide avec chaque Client Final qui couvre, au minimum, l'objet de ce
    Contrat. Aucune disposition du présent contrat n'exclut ou ne limite notre responsabilité en cas de : (i) de fraude
    ou de fausse déclaration frauduleuse de notre part ; (ii) de décès ou de dommages corporels causés par notre
    négligence ; ou (iii) de toute autre responsabilité pour laquelle nous ne pouvons pas exclure ou limiter notre
    responsabilité en vertu de la loi applicable.
  </p>
</section>
<section>
  <h2 i18n="Legal Mention">18. LOI APPLICABLE ET COMPETENCE DES TRIBUNAUX</h2>
  <p i18n="Legal Mention">
    Le présent Contrat est régi par les lois françaises, sans tenir compte des principes de conflit de lois, et les
    tribunaux (ordinaires) du lieu du siège social de Syngenta sont seuls compétents pour toute réclamation relative au
    présent Contrat.
  </p>
</section>
<section>
  <h2 i18n="Legal Mention">19. TERMES DÉFINIS</h2>
  <p i18n="Legal Mention">
    Dans le présent accord, les termes suivants auront les définitions suivantes :<br /><span class="bold"
      >"Compte"</span
    >
    désigne un compte d'utilisateur qu'un utilisateur crée.<br /><span class="bold">" Contenu "</span> désigne les
    éléments suivants et tous les droits de propriété intellectuelle y afférents : (i) les Services ; (ii) la
    conception, les caractéristiques, les fonctionnalités, la navigation et l'apparence des Services ; et (iii) les
    Commentaires.<br /><span class="bold"> " Client final "</span> désigne une entreprise à laquelle vous fournissez un
    service utilisant un ou plusieurs des Services. <br /><span class="bold">" Caractéristique "</span> désigne un
    élément fonctionnel dans un Service. <br /><span class="bold">" Feedback "</span> désigne les évaluations,
    commentaires, critiques, suggestions d'amélioration, opinions et idées soumis par vous ou d'autres utilisateurs par
    le biais des Services. <br /><span class="bold">" Sorties "</span> désigne les prévisions météorologiques, les
    données météorologiques historiques, l'imagerie satellitaire, les conseils d'aide à la décision agronomique, les
    recommandations d'intrants agricoles toute autre sortie que vous obtenez via les Services. <br /><span class="bold"
      >"Service"</span
    >
    désigne un module web, une application pour smartphone ou un autre service, y compris les applications mobiles pour
    la recherche de données agricoles, les analyses, l'aide à la décision en matière de pulvérisation, l'aide à la
    sélection de produits, etc. qui sont mis à la disposition des utilisateurs par nos soins. Les Services peuvent
    contenir une ou plusieurs fonctionnalités et, sauf indication contraire, le terme " Produit " inclut ses
    fonctionnalités. "
    <br /><span class="bold">Conditions de l'offre proposée "</span>
    désigne les conditions générales qui s'appliquent à votre utilisation d'un produit ou d'une ou plusieurs
    fonctionnalités d'un produit.
    <br /><span class="bold">" Marques "</span> désigne les marques commerciales, les marques de service et les logos.
    <br /><span class="bold">" Vos Données d’entrée "</span>
    désigne les informations et les données que vous téléchargez, saisissez, transmettez, stockez ou rendez autrement
    disponibles par le biais des Services, telles que des informations sur une entreprise agricole, l'emplacement d'un
    champ, des cartes, des limites, des informations sur les conditions météorologiques ou climatiques locales, la date
    de semis, la date d'émergence, les heures d'application spécifiques au champ, la date du dernier traitement, les
    scripts d'application spécifiques à la zone du champ pour les produits phytosanitaires, les pratiques agronomiques,
    les pertes de récolte, les rendements des cultures, les observations sur le terrain, les images, les stades de
    croissance spécifiques au champ, les applications précédentes de produits phytosanitaires, vos seuils sélectionnés
    (par exemple pour la pression des ravageurs, des maladies ou des mauvaises herbes).
  </p>
</section>
