<div class="container">
  <p class="c8">
    <span class="c7 c11 c14"
      >Conditions G&eacute;n&eacute;rales d&rsquo;utilisation du service Champ propre&trade;</span
    >
  </p>
  <p class="c0">
    <span class="c7 c5 c11">Date de la version : 04/03/2024</span>
  </p>
  <p class="c0"><span class="c4 c5">&nbsp;</span></p>
  <p class="c0">
    <span class="c7 c2">1. &Agrave; PROPOS DE CES TERMES ET CONDITIONS</span>
  </p>
  <p class="c0"><span class="c4 c3">&nbsp;</span></p>
  <p class="c0">
    <span class="c4 c3"
      >Avant d&#39;utiliser le service, veuillez prendre quelques minutes pour lire attentivement :</span
    >
  </p>
  <p class="c9">
    <span class="c4 c3">(i) les pr&eacute;sentes conditions g&eacute;n&eacute;rales ;</span>
  </p>
  <p class="c9">
    <span class="c3">(ii) notre politique de confidentialit&eacute; (&quot;</span
    ><span class="c2">Politique de confidentialit&eacute;</span
    ><span class="c4 c3"
      >&quot;) - elle contient de plus amples informations sur la mani&egrave;re dont nous traitons les donn&eacute;es
      personnelles en tant que responsable du traitement des donn&eacute;es et sur les droits dont vous disposez ;
      et</span
    >
  </p>
  <p class="c0">
    <span class="c4 c3">(iii) toutes les conditions du pilote propos&eacute;.</span>
  </p>
  <p class="c0"><span class="c4 c3">&nbsp;</span></p>
  <p class="c0">
    <span class="c3"
      >Les pr&eacute;sentes conditions g&eacute;n&eacute;rales et les conditions du pilote propos&eacute; constituent un
      accord juridique contraignant entre vous et nous, concernant l&#39;utilisation de notre service. Nous les
      d&eacute;signons par le terme &quot; </span
    ><span class="c2">Accord &quot;</span
    ><span class="c4 c3"
      >. En cas de conflit ou d&#39;incoh&eacute;rence entre les pr&eacute;sentes conditions g&eacute;n&eacute;rales et
      les conditions du pilote propos&eacute;, les conditions g&eacute;n&eacute;rales du pilote propos&eacute;
      pr&eacute;vaudront.</span
    >
  </p>
  <p class="c0">
    <span class="c4 c3"
      >Pour pouvoir utiliser le Service, vous devez d&#39;abord : (i) cr&eacute;er ou d&eacute;tenir un compte ; et (ii)
      lire et accepter le pr&eacute;sent Accord.</span
    >
  </p>
  <p class="c0">
    <span class="c2"
      >Le Service (ou certaines de ses caract&eacute;ristiques) n&rsquo;est disponible que pour certains utilisateurs,
      il n&rsquo;est disponible que pour une p&eacute;riode limit&eacute;e et </span
    ><span class="c2">il est soumis aux conditions d&rsquo;acc&egrave;s dans le cadre d&rsquo;un pilote technique</span
    ><span class="c2">. </span
    ><span class="c4 c3">Veuillez contacter votre interlocuteur habituel si vous avez des questions.</span>
  </p>
  <p class="c0">
    <span class="c4 c3"
      >Le pr&eacute;sent Accord fait usage de certains termes d&eacute;finis (que nous avons mis en majuscules). Ces
      termes sont d&eacute;finis tout au long de l&#39;accord, ou dans la section 20 (Termes d&eacute;finis).</span
    >
  </p>
  <p class="c0"><span class="c4 c3">&nbsp;</span></p>
  <p class="c0"><span class="c7 c2">2. QUI NOUS SOMMES</span></p>
  <p class="c0"><span class="c4 c3">&nbsp;</span></p>
  <p class="c0">
    <span class="c3">Nous sommes </span><span class="c2">&quot;Syngenta</span
    ><span class="c3">&quot; - Syngenta France SA, 1228 chemin de </span><span class="c3">l&#39;Hobbit</span
    ><span class="c3">, 31790 Saint-Sauveur, France. Dans le pr&eacute;sent Accord, nous utilisons les termes </span
    ><span class="c2">&quot; nous &quot;</span><span class="c3">, </span><span class="c2">&quot; notre &quot; </span
    ><span class="c3">et &quot; </span><span class="c2">nos </span
    ><span class="c4 c3">&quot; pour d&eacute;signer Syngenta et les soci&eacute;t&eacute;s du groupe Syngenta.</span>
  </p>
  <p class="c0"><span class="c4 c3">&nbsp;</span></p>
  <p class="c0"><span class="c7 c2">3. QUI VOUS &Ecirc;TES</span></p>
  <p class="c0"><span class="c4 c3">&nbsp;</span></p>
  <p class="c0">
    <span class="c3"
      >En utilisant notre Service et en acceptant les pr&eacute;sentes conditions g&eacute;n&eacute;rales, vous
      reconnaissez que vous &ecirc;tes un utilisateur professionnel, agissant au nom et pour les besoins de votre
      entreprise, qu&#39;il s&#39;agisse d&#39;une soci&eacute;t&eacute;, d&#39;un entrepreneur individuel ou de toute
      autre forme d&#39;entit&eacute; commerciale (votre </span
    ><span class="c2">&quot;Entreprise&quot;</span
    ><span class="c4 c3">). Nous ne proposons pas notre Service aux consommateurs et/ou particuliers.</span>
  </p>
  <p class="c0">
    <span class="c3">Sauf indication contraire, dans le pr&eacute;sent Accord, les </span
    ><span class="c2">termes &quot;vous&quot; </span><span class="c3">et </span
    ><span class="c2">&quot;votre&quot; </span
    ><span class="c4 c3"
      >d&eacute;signent &agrave; la fois la personne (utilisateur) qui a cr&eacute;&eacute; un compte pour utiliser le
      Service et l&#39;entreprise pour le compte de laquelle le compte a &eacute;t&eacute; cr&eacute;&eacute;.</span
    >
  </p>
  <p class="c0">
    <span class="c4 c3"
      >Veuillez noter que l&#39;Entreprise sera le contr&ocirc;leur de donn&eacute;es en ce qui concerne les
      donn&eacute;es personnelles qui nous sont divulgu&eacute;es lors de l&#39;utilisation du Service - veuillez
      consulter la section 16.</span
    >
  </p>
  <p class="c0"><span class="c4 c3">&nbsp;</span></p>
  <p class="c0">
    <span class="c7 c2">4. MODIFICATIONS DU PR&Eacute;SENT ACCORD</span>
  </p>
  <p class="c0"><span class="c4 c3">&nbsp;</span></p>
  <p class="c0">
    <span class="c4 c3"
      >Nous nous r&eacute;servons le droit d&#39;apporter des modifications au pr&eacute;sent accord ou &agrave; toute
      partie de celui-ci (y compris les pr&eacute;sentes conditions g&eacute;n&eacute;rales) &agrave; tout moment,
      notamment lorsque nous lan&ccedil;ons une mise &agrave; jour.</span
    >
  </p>
  <p class="c0">
    <span class="c4 c3"
      >Si vous n&#39;acceptez pas ces mises &agrave; jour, l&#39;acc&egrave;s au Service peut vous &ecirc;tre
      refus&eacute;.</span
    >
  </p>
  <p class="c0"><span class="c4 c3">&nbsp;</span></p>
  <p class="c0">
    <span class="c7 c2">5. COMMENT FONCTIONNENT LE SERVICE</span>
  </p>
  <p class="c0"><span class="c4 c3">&nbsp;</span></p>
  <p class="c0">
    <span class="c4 c3"
      >Le Service utilise Vos Donn&eacute;es d&rsquo;entr&eacute;e (tels que d&eacute;finies ci-dessous &agrave;
      l&#39;article 19) et les traitent, y compris par le biais de l&#39;extrapolation, de la combinaison avec des
      donn&eacute;es et des informations provenant d&#39;autres sources et de l&#39;utilisation d&#39;algorithmes et de
      mod&egrave;les statistiques d&eacute;velopp&eacute;s par ou pour Syngenta, afin de produire les Sorties.</span
    >
  </p>
  <p class="c0">
    <span class="c3"
      >Les R&eacute;sultats sont destin&eacute;s &agrave; &ecirc;tre utilis&eacute;s pour soutenir et informer les
      d&eacute;cisions agronomiques que vous prenez ind&eacute;pendamment ou, </span
    ><span class="c3"
      >lorsque vous utilisez le Service dans le cadre du pilote que vous mettez en &oelig;uvre aupr&egrave;s de vos
      Clients finaux, par vous et vos Clients finaux</span
    ><span class="c4 c3"
      >. Le Service, y compris les R&eacute;sultats, ne doivent pas &ecirc;tre utilis&eacute;s &agrave; d&#39;autres
      fins.</span
    >
  </p>
  <p class="c0"><span class="c4 c3">&nbsp;</span></p>
  <p class="c0">
    <span class="c2">VEUILLEZ NOTER - </span
    ><span class="c4 c3"
      >Les Sorties fournies par le Service d&eacute;pendent de certains facteurs que nous ne pouvons pas contr&ocirc;ler
      :</span
    >
  </p>
  <p class="c13">
    <span class="c3">&middot;</span
    ><span class="c10">&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
    ><span class="c2">Algorithmes </span
    ><span class="c4 c3"
      >- L&#39;aide &agrave; la d&eacute;cision agronomique est g&eacute;n&eacute;r&eacute;e par des algorithmes
      tr&egrave;s complexes qui ne peuvent pas repr&eacute;senter toutes les particularit&eacute;s du cas individuel.
      Nous cherchons &agrave; am&eacute;liorer constamment notre approche de l&#39;aide &agrave; la d&eacute;cision. Il
      est n&eacute;anmoins essentiel que vous utilisiez votre jugement professionnel quant &agrave; la
      s&eacute;curit&eacute; et &agrave; l&#39;ad&eacute;quation de l&#39;une ou l&#39;autre des Sorties.</span
    >
  </p>
  <p class="c13">
    <span class="c3">&middot;</span
    ><span class="c10">&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
    ><span class="c2">Vos Donn&eacute;es d&rsquo;entr&eacute;e </span
    ><span class="c4 c3"
      >- La qualit&eacute;, l&#39;exactitude et l&#39;actualit&eacute; des donn&eacute;es que vous saisissez auront une
      incidence sur les Sorties.</span
    >
  </p>
  <p class="c13">
    <span class="c3">&middot;</span
    ><span class="c10">&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
    ><span class="c2">Donn&eacute;es de tiers </span
    ><span class="c4 c3"
      >- bien que nous fassions nos meilleurs efforts pour garantir la qualit&eacute; et l&#39;exactitude des
      donn&eacute;es de partenaires tiers, certaines donn&eacute;es de tiers sont mises &agrave; disposition pour
      &ecirc;tre utilis&eacute;es sans garantie qu&#39;elles soient correctes ou exemptes d&#39;erreurs.</span
    >
  </p>
  <p class="c13">
    <span class="c3">&middot;</span
    ><span class="c10">&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
    ><span class="c2"
      >Les donn&eacute;es sur les produits agricoles peuvent ne pas &ecirc;tre enti&egrave;rement &agrave; jour </span
    ><span class="c3 c4"
      >- nous faisons nos meilleurs efforts pour nous assurer que les donn&eacute;es sur les produits agricoles (par
      exemple, les informations sur les vari&eacute;t&eacute;s de semences, les produits de protection des cultures et
      les &eacute;quipements agricoles de Syngenta et de tiers) sont &agrave; jour lorsque nous publions un Service. Des
      donn&eacute;es nouvelles ou actualis&eacute;es sur les produits agricoles peuvent &ecirc;tre publi&eacute;es de
      temps &agrave; autre, mais elles ne seront incorpor&eacute;es dans notre Service que p&eacute;riodiquement,
      lorsque nous publierons une mise &agrave; jour. Par cons&eacute;quent, vous ne devez pas vous fier au Service
      comme &eacute;tant une source de donn&eacute;es sur les produits agricoles qui fait autorit&eacute; et qui est
      &agrave; jour.</span
    >
  </p>
  <p class="c13">
    <span class="c3">&middot;</span
    ><span class="c10">&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
    ><span class="c2">Conditions r&eacute;elles </span
    ><span class="c4 c3"
      >- il se peut que vous n&#39;obteniez pas les r&eacute;sultats souhait&eacute;s pr&eacute;dits par le Service, car
      les conditions r&eacute;elles, comme la m&eacute;t&eacute;o, sont variables et peuvent &ecirc;tre
      diff&eacute;rentes des conditions dans lesquelles les &eacute;quipements ont &eacute;t&eacute; test&eacute;s ou
      pour lesquelles les algorithmes et les mod&egrave;les statistiques ont &eacute;t&eacute;
      d&eacute;velopp&eacute;s.</span
    >
  </p>
  <p class="c13">
    <span class="c3">&middot;</span
    ><span class="c10">&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
    ><span class="c2">La m&eacute;t&eacute;o </span
    ><span class="c4 c3"
      >- en raison de la nature des pr&eacute;visions m&eacute;t&eacute;orologiques, les pr&eacute;visions fournies par
      nos partenaires m&eacute;t&eacute;orologiques ne seront pas enti&egrave;rement exactes.</span
    >
  </p>
  <p class="c13">
    <span class="c3">&middot;</span
    ><span class="c10">&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
    ><span class="c2">Les profils ou param&egrave;tres personnalisables </span
    ><span class="c4 c3">que vous avez sp&eacute;cifi&eacute;s dans le Service, par exemple les seuils de risque.</span>
  </p>
  <p class="c0"><span class="c7 c2">&nbsp;</span></p>
  <p class="c0">
    <span class="c7 c2"
      >Les r&eacute;sultats ne doivent pas &ecirc;tre utilis&eacute;s comme la seule base pour prendre des
      d&eacute;cisions agronomiques et sont donn&eacute;s &agrave; titre indicatif.</span
    >
  </p>
  <p class="c0"><span class="c7 c2">&nbsp;</span></p>
  <p class="c0">
    <span class="c2">Informations sur le Service Syngenta. </span
    ><span class="c4 c3"
      >Toute information contenue ou r&eacute;f&eacute;renc&eacute;e dans le Service concernant Syngenta et ses semences
      ou produits et services de protection des cultures est fournie pour soutenir et informer vos d&eacute;cisions
      agronomiques ind&eacute;pendantes. Pour toute information compl&eacute;mentaire ou toute question concernant nos
      vari&eacute;t&eacute;s de semences, nos produits de protection des cultures ou nos services, veuillez nous
      contacter directement.</span
    >
  </p>
  <p class="c0">
    <span class="c4 c3"
      >Si vous avez l&#39;intention d&#39;utiliser un produit phytosanitaire ou une semence (qu&#39;il s&#39;agisse du
      produit de Syngenta ou d&#39;un tiers), vous devez lire et suivre l&#39;&eacute;tiquette qui accompagne ce produit
      et vous conformer &agrave; toutes les lois et r&eacute;glementations applicables dans votre pays concernant
      l&#39;utilisation de ce produit. Avant d&#39;utiliser un produit, assurez-vous qu&#39;il est homologu&eacute; pour
      &ecirc;tre utilis&eacute; dans votre pays.</span
    >
  </p>
  <p class="c0">
    <span class="c2"
      >En particulier, vous convenez qu&#39;il vous incombe d&#39;inspecter les parcelles et d&#39;exercer votre
      jugement pour d&eacute;cider de suivre les orientations d&#39;aide &agrave; la d&eacute;cision agronomique ou
      d&#39;autres Sorties, et de mettre en &oelig;uvre des pratiques agronomiques conform&eacute;ment aux bonnes
      pratiques agricoles, aux orientations en mati&egrave;re de stewardship et &agrave; toute loi et
      r&eacute;glementation locale applicable. Nous attirons votre attention sur les sections 17 </span
    ><span class="c3">(&quot;Disclaimer&quot;) et </span
    ><span class="c7 c2">18 (&quot;Limitation de responsabilit&eacute;&quot;) ci-dessous.</span>
  </p>
  <p class="c0"><span class="c4 c3">&nbsp;</span></p>
  <p class="c0"><span class="c2 c7">6. MODIFICATIONS DU SERVICE</span></p>
  <p class="c0"><span class="c4 c3">&nbsp;</span></p>
  <p class="c0">
    <span class="c4 c3"
      >Nous nous r&eacute;servons le droit de modifier le Service (y compris ses caract&eacute;ristiques et autres
      contenus et fonctionnalit&eacute;s) de temps &agrave; autre, ce qui peut n&eacute;cessiter des modifications de ce
      Contrat, selon le processus d&eacute;crit ci-dessus. Sauf dans la mesure o&ugrave; cela est express&eacute;ment
      pr&eacute;vu dans ce Contrat, Syngenta ne sera pas responsable envers vous de quelque mani&egrave;re que ce soit
      des cons&eacute;quences possibles des changements apport&eacute;s au Service ou &agrave; ce Contrat.</span
    >
  </p>
  <p class="c0"><span class="c4 c3">&nbsp;</span></p>
  <p class="c0">
    <span class="c7 c2">7. S&Eacute;CURIT&Eacute; DU MOT DE PASSE</span>
  </p>
  <p class="c0"><span class="c4 c3">&nbsp;</span></p>
  <p class="c0">
    <span class="c4 c3"
      >Il vous incombe de veiller &agrave; la s&eacute;curit&eacute; du mot de passe de votre compte. Vous devez
      notamment choisir un mot de passe complexe qui n&#39;est pas utilis&eacute; pour d&#39;autres comptes, ne pas
      partager votre mot de passe avec une autre personne et ne pas lui donner acc&egrave;s &agrave; votre compte. Si
      vous oubliez votre mot de passe, vous pouvez le r&eacute;initialiser.</span
    >
  </p>
  <p class="c0"><span class="c4 c3">&nbsp;</span></p>
  <p class="c0">
    <span class="c7 c2">8. DROITS DE PROPRI&Eacute;T&Eacute;</span>
  </p>
  <p class="c0"><span class="c4 c3">&nbsp;</span></p>
  <p class="c0">
    <span class="c3"
      >Le Service contient des Marques et des Contenus qui sont la propri&eacute;t&eacute; de Syngenta ou de ses
      conc&eacute;dants et qui sont prot&eacute;g&eacute;s par la propri&eacute;t&eacute; intellectuelle et d&#39;autres
      droits et lois. Syngenta et ses conc&eacute;dants de licence conservent la propri&eacute;t&eacute; exclusive du
      Service, de son Contenu et des Marques commerciales. Vous n&#39;&ecirc;tes pas autoris&eacute; &agrave;
      reproduire, afficher ou utiliser de quelque mani&egrave;re que ce soit toute Marque commerciale, sauf dans les cas
      pr&eacute;vus ci-dessous. </span
    ><span class="c2"
      >Lorsque vous utilisez le Service dans le cadre du pilote que vous mettez en &oelig;uvre aupr&egrave;s de vos
      Clients finaux, vous ne devez pas vous pr&eacute;senter comme le propri&eacute;taire ou le conc&eacute;dant
      exclusif du Service, de leur Contenu et des Marques commerciales, ni les faire passer pour les v&ocirc;tres
      lorsque vous traitez avec vos Clients finaux</span
    ><span class="c4 c3">.</span>
  </p>
  <p class="c0"><span class="c4 c3">&nbsp;</span></p>
  <p class="c0">
    <span class="c7 c2">9. VOTRE LICENCE D&#39;UTILISATION DU SERVICE</span>
  </p>
  <p class="c0"><span class="c4 c3">&nbsp;</span></p>
  <p class="c0">
    <span class="c3"
      >Vous disposez d&#39;un droit non exclusif, non transf&eacute;rable et non sous-licenciable d&#39;utiliser le
      Service et de t&eacute;l&eacute;charger, ex&eacute;cuter, stocker, imprimer, copier et partager les
      r&eacute;sultats du Service pendant la dur&eacute;e de validit&eacute; du pr&eacute;sent contrat, &agrave;
      condition que vous n&#39;utilisiez les r&eacute;sultats qu&#39;au sein de votre entreprise (ou, </span
    ><span class="c3"
      >lorsque vous utilisez le Service dans le cadre du pilote que vous mettez en &oelig;uvre aupr&egrave;s de vos
      Clients finaux, au sein des entreprises de vos Clients finaux</span
    ><span class="c3"
      >) d&#39;une mani&egrave;re conforme au pr&eacute;sent contrat (en particulier l&#39;article 5) et vous ne
      modifiez pas le Service de quelque mani&egrave;re que ce soit (par exemple, en supprimant ou en modifiant les
      Marques ou autres avis). Vous ne pouvez pas vendre, louer, accorder une licence ou transf&eacute;rer de quelque
      mani&egrave;re que ce soit le Service &agrave; des tiers ou g&eacute;n&eacute;rer un Service pour des tiers
      (</span
    ><span class="c3">sauf, </span><span class="c3">lorsque</span
    ><span class="c3"
      >&nbsp;vous utilisez le Service dans le cadre du pilote que vous mettez en &oelig;uvre aupr&egrave;s de vos
      Clients finaux</span
    ><span class="c4 c3"
      >). Toute tierce partie souhaitant utiliser le Service doit cr&eacute;er son propre Compte.</span
    >
  </p>
  <p class="c0">
    <span class="c3"
      >&Agrave; l&#39;exception de ce qui est indiqu&eacute; ci-dessus, aucun autre droit sur le Service ou les Sorties
      ou sur tout autre contenu ne vous est transf&eacute;r&eacute;. Par </span
    ><span class="c3">exemple (sans limitation)</span
    ><span class="c4 c3"
      >, vous ne pouvez pas faire ce qui suit sans avoir obtenu au pr&eacute;alable l&#39;autorisation &eacute;crite de
      Syngenta : publier, afficher ou transf&eacute;rer le Service ou cr&eacute;er un lien vers ceux-ci &agrave; partir
      de tout autre site accessible au public.</span
    >
  </p>
  <p class="c0"><span class="c4 c3">&nbsp;</span></p>
  <p class="c0"><span class="c7 c2">10. GARANTIE LIMIT&Eacute;E</span></p>
  <p class="c0"><span class="c4 c3">&nbsp;</span></p>
  <p class="c0">
    <span class="c3"
      >Nous garantissons que, lorsqu&#39;ils sont utilis&eacute;s correctement et conform&eacute;ment au pr&eacute;sent
      contrat et &agrave; toute autre instruction &eacute;mise avec ou dans le cadre du Service, le Service fonctionnera
      essentiellement conform&eacute;ment au pr&eacute;sent contrat. </span
    ><span class="c3"
      >Lorsque vous utilisez le Service dans le cadre du pilote que vous mettez en &oelig;uvre aupr&egrave;s de vos
      Clients finaux</span
    ><span class="c4 c3"
      >, vous reconnaissez que cette garantie est fournie &agrave; votre entreprise uniquement et ne peut &ecirc;tre
      invoqu&eacute;e par aucun client final.</span
    >
  </p>
  <p class="c0"><span class="c4 c3">&nbsp;</span></p>
  <p class="c0">
    <span class="c7 c2">11. VOS OBLIGATIONS ENVERS SYNGENTA</span>
  </p>
  <p class="c0"><span class="c4 c3">&nbsp;</span></p>
  <p class="c0">
    <span class="c4 c3">Vous d&eacute;clarez et garantissez que :</span>
  </p>
  <p class="c9">
    <span class="c1">(i) </span
    ><span class="c4 c3"
      >Vos Donn&eacute;es d&rsquo;entr&eacute;e (telles que d&eacute;finies ci-dessous &agrave; l&#39;article 19) ont
      &eacute;t&eacute; obtenues conform&eacute;ment &agrave; toutes les lois applicables et continuent de
      l&#39;&ecirc;tre, et vous disposez des droits requis pour saisir ou t&eacute;l&eacute;charger vos donn&eacute;es
      dans le Service et pour accorder la licence d&eacute;crite ci-dessous en vertu de l&#39;article 12 (&quot; Votre
      concession de licence &agrave; Syngenta &quot;) ;</span
    >
  </p>
  <p class="c9">
    <span class="c4 c3"
      >(ii) Lorsque Vos Donn&eacute;es d&rsquo;entr&eacute;e comprennent des donn&eacute;es personnelles relatives
      &agrave; un individu autre que vous-m&ecirc;me (par exemple, le nom, l&#39;adresse &eacute;lectronique, les
      donn&eacute;es de g&eacute;olocalisation de l&#39;appareil, l&#39;imagerie), vous (en tant qu&#39;Entreprise
      concern&eacute;e) avez &eacute;tabli une base l&eacute;gale valide en vertu des lois applicables sur la protection
      des donn&eacute;es pour traiter ces donn&eacute;es &agrave; caract&egrave;re personnel, et vous vous conformerez
      par ailleurs &agrave; vos obligations en tant que responsable du traitement de ces donn&eacute;es (voir la section
      16), y compris en mati&egrave;re de transparence ;</span
    >
  </p>
  <p class="c9">
    <span class="c4 c3"
      >(iii) Vos Donn&eacute;es d&rsquo;entr&eacute;e ne portent pas atteinte &agrave; la propri&eacute;t&eacute;
      intellectuelle, &agrave; la vie priv&eacute;e ou &agrave; d&#39;autres droits d&#39;un tiers et Syngenta
      n&#39;aura pas besoin d&#39;obtenir des licences ou de payer des redevances &agrave; un tiers ; et</span
    >
  </p>
  <p class="c9">
    <span class="c4 c3"
      >(iv) Vous garderez les informations de votre compte (y compris les d&eacute;tails de votre) &agrave; jour
      &agrave; tout moment.</span
    >
  </p>
  <p class="c0">
    <span class="c7 c2">12. VOTRE CONCESSION DE LICENCE &Agrave; SYNGENTA</span>
  </p>
  <p class="c0"><span class="c4 c3">&nbsp;</span></p>
  <p class="c0">
    <span class="c4 c3"
      >Le Service peut n&eacute;cessiter Vos Donn&eacute;es d&rsquo;entr&eacute;e (tels que d&eacute;finis ci-dessous
      &agrave; l&#39;article 19). Par la pr&eacute;sente, vous nous accordez un droit non exclusif, mondial,
      transf&eacute;rable, libre de redevance, pouvant faire l&#39;objet d&#39;une sous-licence et perp&eacute;tuel
      d&#39;utiliser Vos Donn&eacute;es d&rsquo;entr&eacute;e afin de produire les Sorties et de rendre possible votre
      utilisation du Service, ainsi que pour am&eacute;liorer notre Service, d&eacute;velopper de nouveaux Services,
      am&eacute;liorer nos mod&egrave;les et tout autre objectif que nous d&eacute;terminons, sous r&eacute;serve des
      conditions du pr&eacute;sent accord. Il se peut que vous ne puissiez pas utiliser certaines
      caract&eacute;ristiques du Service si vous ne fournissez pas les informations requises. Entre vous et Syngenta,
      vous &ecirc;tes propri&eacute;taire de Vos Donn&eacute;es d&rsquo;entr&eacute;e.</span
    >
  </p>
  <p class="c0">
    <span class="c2">Lorsque vous utilisez le Service </span
    ><span class="c2">dans le cadre du pilote que vous mettez en &oelig;uvre aupr&egrave;s de vos clients finaux</span
    ><span class="c7 c2"
      >, vous reconnaissez qu&#39;il est de votre responsabilit&eacute; d&#39;obtenir de vos clients finaux tous les
      droits, autorisations ou licences n&eacute;cessaires pour nous accorder la licence pr&eacute;vue par le
      pr&eacute;sent article 12.</span
    >
  </p>
  <p class="c0"><span class="c4 c3">&nbsp;</span></p>
  <p class="c0">
    <span class="c7 c2">13. NOTRE UTILISATION DES DONN&Eacute;ES ANONYMISEES</span>
  </p>
  <p class="c0"><span class="c4 c3">&nbsp;</span></p>
  <p class="c0">
    <span class="c3"
      >Dans la mesure o&ugrave; Vos Donn&eacute;es d&rsquo;entr&eacute;e comprennent des donn&eacute;es &agrave;
      caract&egrave;re personnel, nous </span
    ><span class="c3">n&#39;utiliserons</span
    ><span class="c3"
      >&nbsp;ces donn&eacute;es &agrave; caract&egrave;re personnel que conform&eacute;ment &agrave; notre Politique de
      confidentialit&eacute; et &agrave; l&#39;article 15. Nonobstant ce qui pr&eacute;c&egrave;de, vous reconnaissez
      que nous pouvons agr&eacute;ger ou anonymiser les donn&eacute;es &agrave; caract&egrave;re personnel contenues
      dans Vos Contributions afin qu&#39;elles ne constituent plus des donn&eacute;es &agrave; caract&egrave;re
      personnel mais des donn&eacute;es anonymis&eacute;es (</span
    ><span class="c2">&quot;Donn&eacute;es anonymis&eacute;es&quot;</span><span class="c4 c3">).</span>
  </p>
  <p class="c0"><span class="c7 c2">&nbsp;</span></p>
  <p class="c0">
    <span class="c7 c2">14. RESTRICTIONS CONCERNANT VOTRE UTILISATION DU SERVICE</span>
  </p>
  <p class="c0"><span class="c4 c3">&nbsp;</span></p>
  <p class="c0">
    <span class="c4 c3"
      >Vous acceptez de respecter la loi applicable lorsque vous utilisez le Service. Vous acceptez de ne pas utiliser
      de syst&egrave;me automatis&eacute; pour acc&eacute;der au Service, de ne pas faciliter l&#39;acc&egrave;s non
      autoris&eacute; d&#39;une personne au Service et de ne pas modifier la fonctionnalit&eacute; ou la
      disponibilit&eacute; du Service. Vous acceptez de ne pas d&eacute;compiler, inverser la compilation,
      d&eacute;sassembler, faire de l&#39;ing&eacute;nierie inverse ou r&eacute;duire de toute autre mani&egrave;re
      &agrave; une forme perceptible par l&#39;homme tout ou partie du Service ou de leur Contenu, ni de tenter de
      d&eacute;river le code source ou de cr&eacute;er des &oelig;uvres d&eacute;riv&eacute;es.</span
    >
  </p>
  <p class="c0"><span class="c4 c3">&nbsp;</span></p>
  <p class="c0">
    <span class="c7 c2">15. CONDITIONS DE TRAITEMENT DES DONN&Eacute;ES</span>
  </p>
  <p class="c0"><span class="c4 c3">&nbsp;</span></p>
  <p class="c0">
    <span class="c2"
      >Vous comprenez que toutes les donn&eacute;es &agrave; caract&egrave;re personnel comprises dans </span
    ><span class="c2">Vos</span
    ><span class="c2"
      >&nbsp;Donn&eacute;es d&rsquo;entr&eacute;e que nous utilisons afin de produire les Sorties sont trait&eacute;es
      par nous, en tant que processeur de donn&eacute;es, pour votre compte (en tant qu&#39;Entreprise concern&eacute;e)
      en tant que contr&ocirc;leur de donn&eacute;es. </span
    ><span class="c3"
      >En ce qui concerne ces donn&eacute;es &agrave; caract&egrave;re personnel, nous nous conformerons, en tant que
      processeur de donn&eacute;es, aux dispositions de l&#39;article 28(3) du R&egrave;glement g&eacute;n&eacute;ral
      sur la protection des donn&eacute;es (</span
    ><span class="c3">&quot; </span><span class="c2">RGPD</span><span class="c2">&nbsp;</span
    ><span class="c4 c3"
      >&quot;), qui sont par les pr&eacute;sentes incorpor&eacute;es par r&eacute;f&eacute;rence au pr&eacute;sent
      Contrat. Aux fins de l&#39;article 28(2) du RGPD, nous sommes g&eacute;n&eacute;ralement autoris&eacute;s &agrave;
      d&eacute;signer des sous-traitants secondaires, &agrave; condition que ces d&eacute;signations soient conformes
      aux dispositions de l&#39;article 28(4) du RGPD. Aux fins de l&#39;article 28(3)(h) du RGPD, vous reconnaissez que
      votre droit &agrave; un audit ou &agrave; une inspection sera en premier lieu satisfait par la vue d&#39;un
      rapport d&#39;audit interne. Si un tel rapport ne r&eacute;pond pas &agrave; vos pr&eacute;occupations, vous aurez
      le droit, &agrave; vos frais, de ne pas effectuer plus d&#39;un audit (par vous-m&ecirc;me ou par un auditeur
      mandat&eacute;) par ann&eacute;e civile.</span
    >
  </p>
  <p class="c0">
    <span class="c3"
      >Vous comprenez en outre que les obligations ci-dessus ne s&#39;appliquent qu&#39;aux donn&eacute;es &agrave;
      caract&egrave;re personnel que nous traitons en votre nom en tant que sous-traitant, et non aux donn&eacute;es
      &agrave; caract&egrave;re personnel que nous traitons pour nos propres besoins en tant que contr&ocirc;leur de
      donn&eacute;es. Veuillez-vous r&eacute;f&eacute;rer &agrave; notre politique de confidentialit&eacute; pour plus
      d&#39;informations sur notre traitement des donn&eacute;es personnelles en tant que contr&ocirc;leur de
      donn&eacute;es. </span
    ><span class="c3"
      >Lorsque vous utilisez le Service dans le cadre du pilote que vous mettez en &oelig;uvre aupr&egrave;s de vos
      Clients finaux</span
    ><span class="c4 c3"
      >, vous devez vous assurer que chaque Client final, ainsi que chaque personne concern&eacute;e associ&eacute;e
      &agrave; un Client final dont les donn&eacute;es personnelles nous sont fournies, a &eacute;t&eacute;
      dirig&eacute; vers notre Politique de confidentialit&eacute;.</span
    >
  </p>
  <p class="c0">
    <span class="c4 c3"
      >En tant que responsable du traitement des donn&eacute;es, vous reconnaissez qu&#39;il vous incombe de garantir la
      l&eacute;galit&eacute; du traitement des donn&eacute;es &agrave; caract&egrave;re personnel, ce qui inclut
      l&#39;obligation de fournir un avis de confidentialit&eacute; des donn&eacute;es aux personnes concern&eacute;es
      dont les donn&eacute;es &agrave; caract&egrave;re personnel sont trait&eacute;es dans le cadre de la production
      des Sorties.</span
    >
  </p>
  <p class="c0"><span class="c4 c3">&nbsp;</span></p>
  <p class="c0">
    <span class="c7 c2">16. CLAUSE DE NON-RESPONSABILIT&Eacute;</span>
  </p>
  <p class="c0"><span class="c4 c3">&nbsp;</span></p>
  <p class="c0">
    <span class="c4 c3"
      >Le Service et leur contenu sont fournis &quot; tels quels &quot;, &quot; tels que disponibles &quot;.</span
    >
  </p>
  <p class="c0">
    <span class="c4 c3"
      >Nous ne garantissons pas que le Service sera toujours disponible. Bien que nous nous effor&ccedil;ons de
      minimiser les perturbations et de vous informer &agrave; l&#39;avance des interruptions pr&eacute;vues, nous nous
      r&eacute;servons le droit de fermer le Service sans pr&eacute;avis.</span
    >
  </p>
  <p class="c0">
    <span class="c4 c3"
      >Dans toute la mesure permise par la loi applicable, et &agrave; l&#39;exception de ce qui est express&eacute;ment
      &eacute;tabli dans ce Contrat, Syngenta et nos fournisseurs de services et conc&eacute;dants de licence respectifs
      d&eacute;clinent toute repr&eacute;sentation et garantie de toute sorte, y compris : (a) toutes les garanties de
      qualit&eacute; marchande, d&#39;ad&eacute;quation &agrave; un usage particulier, de titre et d&#39;absence de
      contrefa&ccedil;on en ce qui concerne le Service et leur Contenu ; (b) toutes les garanties relatives aux retards,
      interruptions, erreurs ou omissions dans le fonctionnement du Service ou de toute partie du Service ; (c) toutes
      les garanties relatives &agrave; la transmission ou &agrave; la livraison du Service ou &agrave; leur
      disponibilit&eacute; &agrave; tout moment ou endroit particulier ; (d) toutes les garanties relatives &agrave; la
      disponibilit&eacute; de vos entr&eacute;es - vous devez conserver des copies de celles-ci et ne pas vous appuyer
      sur notre Service comme principal moyen de les stocker ; (e) toutes les garanties relatives &agrave; la
      s&eacute;curit&eacute; du Service ou &agrave; l&#39;absence de virus, de vers informatiques ou d&#39;autres codes
      pouvant pr&eacute;senter des propri&eacute;t&eacute;s contaminantes ou destructrices ; et (f) toutes les garanties
      relatives &agrave; l&#39;utilisation, la validit&eacute;, l&#39;exactitude, l&#39;actualit&eacute;,
      l&#39;exhaustivit&eacute;, la pertinence, la fiabilit&eacute; et la disponibilit&eacute; du Service et du contenu.
      Notre Service peut contenir des hyperliens vers des sites Web qui ne sont pas exploit&eacute;s par nous ou par
      d&#39;autres soci&eacute;t&eacute;s du groupe Syngenta. Ces hyperliens sont fournis &agrave; titre de
      r&eacute;f&eacute;rence et de commodit&eacute; uniquement, et n&#39;impliquent aucune approbation du
      mat&eacute;riel sur ces sites Web tiers ni aucune association avec leurs op&eacute;rateurs. Syngenta ne
      contr&ocirc;le pas ces sites Web et n&#39;est pas responsable de leur contenu. Vous acc&eacute;dez &agrave; ces
      sites Web et les utilisez uniquement &agrave; vos propres risques.</span
    >
  </p>
  <p class="c0"><span class="c7 c2">&nbsp;</span></p>
  <p class="c0">
    <span class="c7 c2">17. LIMITATION DE LA RESPONSABILIT&Eacute;</span>
  </p>
  <p class="c0"><span class="c4 c3">&nbsp;</span></p>
  <p class="c0">
    <span class="c7 c2"
      >L&rsquo;Agriculteur est seul responsable et d&eacute;cisionnaire de la gestion et du suivi de ses parcelles,
      int&eacute;grant la d&eacute;finition de la strat&eacute;gie des traitements herbicides, le suivi technique et les
      &eacute;ventuelles applications n&eacute;cessaires en produits herbicides. L&rsquo;Agriculteur est seul
      responsable de la qualit&eacute; des informations fournies &agrave; l&rsquo;OAD dans le cadre de
      l&rsquo;auto-diagnostic, et seul responsable du choix du programme et des produits herbicides si n&eacute;cessaire
      dans le cadre de ce Pilote.</span
    >
  </p>
  <p class="c0"><span class="c7 c2">&nbsp;</span></p>
  <p class="c0">
    <span class="c7 c2"
      >Le Pilote propos&eacute; par Syngenta n&rsquo;a pas vocation &agrave; se substituer aux bonnes pratiques
      agronomiques et r&eacute;glementaires, y compris &agrave; une surveillance r&eacute;guli&egrave;re des champs, et
      ne doit pas &ecirc;tre consid&eacute;r&eacute; comme le seul moyen de prendre des d&eacute;cisions agronomiques,
      financi&egrave;res ou de gestion des risques. Le but de ce Pilote est d&rsquo;accompagner l&rsquo;Agriculteur
      &agrave; am&eacute;liorer ses prises de d&eacute;cision, mais n&rsquo;a pas vocation &agrave; remplacer les
      agronomes, conseillers financiers, assureurs, agents ou courtiers en produits agricoles.</span
    >
  </p>
  <p class="c0"><span class="c7 c2">&nbsp;</span></p>
  <p class="c0">
    <span class="c2"
      >Lorsque vous utilisez le Service dans le cadre d&#39;un pilote que vous mettez en &oelig;uvre aupr&egrave;s de
      vos Clients finaux</span
    ><span class="c7 c2"
      >, vous reconnaissez express&eacute;ment que : (i) vous &ecirc;tes directement responsable envers nous des actes
      et omissions de vos Clients finaux (y compris tout repr&eacute;sentant de vos Clients finaux qui utilise le
      Service) ; et (ii) Syngenta n&#39;aura aucune responsabilit&eacute; envers vos Clients finaux pour toute violation
      par nous ou par vous de ce Contrat.</span
    >
  </p>
  <p class="c0">
    <span class="c7 c2"
      >Aucune disposition du pr&eacute;sent contrat n&#39;exclut ou ne limite notre responsabilit&eacute; en cas de :
      (i) de fraude ou de fausse d&eacute;claration frauduleuse de notre part ; (ii) de d&eacute;c&egrave;s ou de
      dommages corporels caus&eacute;s par notre n&eacute;gligence ; ou (iii) de toute autre responsabilit&eacute; pour
      laquelle nous ne pouvons pas exclure ou limiter notre responsabilit&eacute; en vertu de la loi applicable.</span
    >
  </p>
  <p class="c0"><span class="c4 c3">&nbsp;</span></p>
  <p class="c0">
    <span class="c7 c2">18. LOI APPLICABLE ET COMPETENCE DES TRIBUNAUX</span>
  </p>
  <p class="c0"><span class="c4 c3">&nbsp;</span></p>
  <p class="c0">
    <span class="c4 c3"
      >Le pr&eacute;sent Contrat est r&eacute;gi par les lois fran&ccedil;aises, sans tenir compte des principes de
      conflit de lois, et les tribunaux (ordinaires) du lieu du si&egrave;ge social de Syngenta sont seuls
      comp&eacute;tents pour toute r&eacute;clamation relative au pr&eacute;sent Contrat.</span
    >
  </p>
  <p class="c0"><span class="c4 c3">&nbsp;</span></p>
  <p class="c0"><span class="c7 c2">19. TERMES D&Eacute;FINIS</span></p>
  <p class="c0"><span class="c4 c3">&nbsp;</span></p>
  <p class="c0">
    <span class="c4 c3"
      >Dans le pr&eacute;sent accord, les termes suivants auront les d&eacute;finitions suivantes :</span
    >
  </p>
  <p class="c0">
    <span class="c2">&quot;Compte&quot; </span
    ><span class="c4 c3">d&eacute;signe un compte d&#39;utilisateur qu&#39;un utilisateur cr&eacute;e.</span>
  </p>
  <p class="c0">
    <span class="c2">&quot; Contenu &quot; </span
    ><span class="c4 c3"
      >d&eacute;signe les &eacute;l&eacute;ments suivants et tous les droits de propri&eacute;t&eacute; intellectuelle y
      aff&eacute;rents : (i) le Service ; (ii) la conception, les caract&eacute;ristiques, les fonctionnalit&eacute;s,
      la navigation et l&#39;apparence du Service ; et (iii) les Commentaires.</span
    >
  </p>
  <p class="c0">
    <span class="c2">&quot; Client </span><span class="c2">final &quot; </span><span class="c3">d&eacute;signe</span
    ><span class="c4 c3">&nbsp;une entreprise &agrave; laquelle vous fournissez un service utilisant le Service.</span>
  </p>
  <p class="c0">
    <span class="c2">&quot; Caract&eacute;ristique &quot; </span
    ><span class="c4 c3">d&eacute;signe un &eacute;l&eacute;ment fonctionnel dans le Service.</span>
  </p>
  <p class="c0">
    <span class="c2">&quot; Feedback </span><span class="c2">&quot; </span><span class="c3">d&eacute;signe</span
    ><span class="c4 c3"
      >&nbsp;les &eacute;valuations, commentaires, critiques, suggestions d&#39;am&eacute;lioration, opinions et
      id&eacute;es soumis par vous ou d&#39;autres utilisateurs par le biais du Service.</span
    >
  </p>
  <p class="c0">
    <span class="c2">&quot; Sorties </span><span class="c2">&quot; </span><span class="c3">d&eacute;signe</span
    ><span class="c4 c3"
      >&nbsp;les pr&eacute;visions m&eacute;t&eacute;orologiques, les donn&eacute;es m&eacute;t&eacute;orologiques
      historiques, l&#39;imagerie satellitaire, les conseils d&#39;aide &agrave; la d&eacute;cision agronomique, les
      recommandations d&#39;intrants agricoles et toute autre sortie que vous obtenez via le Service.</span
    >
  </p>
  <p class="c0">
    <span class="c2">&quot;Service&quot; </span
    ><span class="c4 c3"
      >d&eacute;signe un module web, une application pour smartphone ou un autre service, y compris les applications
      mobiles pour la recherche de donn&eacute;es agricoles, les analyses, l&#39;aide &agrave; la d&eacute;cision en
      mati&egrave;re de pulv&eacute;risation, l&#39;aide &agrave; la s&eacute;lection de produits, etc. qui sont mis
      &agrave; la disposition des utilisateurs par nos soins. Le Service peut contenir une ou plusieurs
      fonctionnalit&eacute;s et, sauf indication contraire, le terme &quot; Produit &quot; inclut ses
      fonctionnalit&eacute;s.</span
    >
  </p>
  <p class="c0">
    <span class="c2">&quot; Conditions de l&#39;offre propos&eacute;e d&eacute;signe</span
    ><span class="c4 c3"
      >&nbsp;les conditions g&eacute;n&eacute;rales qui s&#39;appliquent &agrave; votre utilisation d&#39;un produit ou
      d&#39;une ou plusieurs fonctionnalit&eacute;s d&#39;un produit.</span
    >
  </p>
  <p class="c0">
    <span class="c2">&quot; Marques &quot; </span
    ><span class="c4 c3">d&eacute;signe les marques commerciales, les marques de service et les logos.</span>
  </p>
  <p class="c15">
    <span class="c2">&quot; Vos Donn&eacute;es d&rsquo;entr&eacute;e &quot; </span
    ><span class="c3"
      >d&eacute;signe les informations et les donn&eacute;es que vous t&eacute;l&eacute;chargez, saisissez, transmettez,
      stockez ou rendez autrement disponibles par le biais du Service, telles que des informations sur une entreprise
      agricole, l&#39;emplacement d&#39;un champ, des cartes, des limites, des informations sur les conditions
      m&eacute;t&eacute;orologiques ou climatiques locales, la date de semis, la date d&#39;&eacute;mergence, les heures
      d&#39;application sp&eacute;cifiques au champ, la date du dernier traitement, les scripts d&#39;application
      sp&eacute;cifiques &agrave; la zone du champ pour les produits phytosanitaires, les pratiques agronomiques, les
      pertes de r&eacute;colte, les rendements des cultures, les observations sur le terrain, les images, les stades de
      croissance sp&eacute;cifiques au champ, les applications pr&eacute;c&eacute;dentes de produits phytosanitaires,
      vos seuils s&eacute;lectionn&eacute;s (par exemple pour la pression des ravageurs, des maladies ou des mauvaises
      herbes).</span
    >
  </p>
</div>
