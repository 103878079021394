import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'smv-quali-h2o-legal-notices',
  standalone: true,
  imports: [CommonModule, MatDividerModule],
  templateUrl: './quali-h2o-legal-notices.component.html',
  styleUrls: ['./quali-h2o-legal-notices.component.scss'],
})
export class QualiH2OLegalNoticesComponent {}
