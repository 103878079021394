<div class="smv-table-management">
  @if (generalActions && !generalActions.tableOnly) {
    <div [ngClass]="{ 'smv-page-layout': generalActions.pageLayout }">
      <div class="smv-actions">
        @if (!generalActions.searchDisabled && generalActions.searchPlaceholder) {
          <smv-search-field
            class="search-field"
            [placeholder]="generalActions.searchPlaceholder"
            (search)="search($event)"
          />
        }

        <div class="action-buttons">
          @if (showUserFilter) {
            <div>
              <button mat-mini-fab color="success" [matMenuTriggerFor]="menu">
                <mat-icon>manage_search</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <form (click)="$event.stopPropagation()" #formFiltresRef>
                  <div mat-menu-item>
                    <mat-slide-toggle
                      i18n="Slider|Is editor"
                      name="slideInput"
                      [(ngModel)]="inputSliderValues['editor']"
                      (change)="search(this.searchInput)"
                    >
                      Est éditeur
                    </mat-slide-toggle>
                  </div>
                  <div mat-menu-item>
                    <mat-slide-toggle
                      i18n="Slider|Is administrator"
                      name="slideInput"
                      [(ngModel)]="inputSliderValues['admin']"
                      (change)="search(this.searchInput)"
                    >
                      Est administrateur
                    </mat-slide-toggle>
                  </div>
                  <div mat-menu-item>
                    <mat-slide-toggle
                      i18n="Slider|Is right manager"
                      name="slideInput"
                      [(ngModel)]="inputSliderValues['rightAdmin']"
                      (change)="search(this.searchInput)"
                    >
                      Est gestionnaire de droits
                    </mat-slide-toggle>
                  </div>
                  <div mat-menu-item>
                    <mat-slide-toggle
                      i18n="Slider|Is reader"
                      name="slideInput"
                      [(ngModel)]="inputSliderValues['reader']"
                      (change)="search(this.searchInput)"
                    >
                      Est lecteur
                    </mat-slide-toggle>
                  </div>
                  <div mat-menu-item>
                    <mat-slide-toggle
                      i18n="Slider|Is validator"
                      name="slideInput"
                      [(ngModel)]="inputSliderValues['validator']"
                      (change)="search(this.searchInput)"
                    >
                      Est validateur
                    </mat-slide-toggle>
                  </div>
                  <div mat-menu-item>
                    <mat-slide-toggle
                      i18n="Slider|Is specifier"
                      name="slideInput"
                      [(ngModel)]="inputSliderValues['specifier']"
                      (change)="search(this.searchInput)"
                    >
                      Est spécificateur
                    </mat-slide-toggle>
                  </div>
                  <mat-divider></mat-divider>
                  <div mat-menu-item>
                    <mat-slide-toggle
                      i18n="Slider|Is admin"
                      name="slideInput"
                      [(ngModel)]="inputSliderValues['superAdmin']"
                      (change)="search(this.searchInput)"
                    >
                      Est administrateur
                    </mat-slide-toggle>
                  </div>
                </form>
              </mat-menu>
            </div>
          }

          @if (
            !generalActions.rightActionDisabled && generalActions.rightActionTooltip && generalActions.rightActionIcon
          ) {
            <button
              mat-mini-fab
              color="secondary"
              (click)="openRightAction()"
              [matTooltip]="generalActions.rightActionTooltip"
            >
              <mat-icon>{{ generalActions.rightActionIcon }}</mat-icon>
            </button>
          }

          @if (!generalActions.refreshDisabled) {
            <button mat-mini-fab color="secondary" (click)="onRefresh()" matTooltip="Actualiser" i18n-matTooltip>
              <mat-icon>refresh</mat-icon>
            </button>
          }

          @if (!generalActions.addDisabled && generalActions.addTooltip) {
            <button mat-mini-fab color="success" (click)="onAdd()" [matTooltip]="generalActions.addTooltip">
              <mat-icon>{{ generalActions.addIcon }}</mat-icon>
            </button>
          }

          @if (!generalActions.deleteSelectedDisabled && generalActions.deleteTooltip) {
            <button
              mat-mini-fab
              color="warn"
              (click)="askToDelete()"
              [matTooltip]="generalActions.deleteTooltip"
              [disabled]="!allSelected && !someSelected()"
            >
              <mat-icon>delete_forever</mat-icon>
            </button>
          }

          @if (
            !generalActions.actionSelectedDisabled &&
            generalActions.actionSelectedTooltip &&
            generalActions.actionSelectedIcon
          ) {
            <button
              mat-mini-fab
              color="secondary"
              (click)="onActionSelected()"
              [matTooltip]="generalActions.actionSelectedTooltip"
              [disabled]="!allSelected && !someSelected()"
            >
              <mat-icon>{{ generalActions.actionSelectedIcon }}</mat-icon>
            </button>
          }
        </div>
      </div>
    </div>
  }

  <div class="smv-list">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      [matSortDisabled]="sortDisabled"
      (matSortChange)="sortData($event)"
    >
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            [checked]="allSelected"
            color="primary"
            [indeterminate]="someSelected()"
            (change)="selectAll($event.checked)"
          />
        </th>
        <td mat-cell *matCellDef="let row; index as i">
          @if (!(generalActions?.hideCheckboxCondition && generalActions?.hideCheckboxCondition(row))) {
            <mat-checkbox
              color="accent"
              [(ngModel)]="task.subtasks[i].selected"
              (ngModelChange)="updateAllSelected()"
            />
          }
        </td>
      </ng-container>
      @for (column of columnsDef; track column.field) {
        <ng-container matColumnDef="{{ column.field }}">
          <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!column.sort">
            {{ column.label }}
            @if (column.helpText) {
              <smv-help-overlay>
                <div>{{ column.helpText }}</div>
              </smv-help-overlay>
            }
          </th>
          @switch (column.type) {
            @case (CellType.Boolean) {
              <td mat-cell *matCellDef="let row" class="text-nowrap">
                @if (row[column.cellData]) {
                  <mat-icon>check_box</mat-icon>
                } @else {
                  <mat-icon>check_box_outline_blank</mat-icon>
                }
              </td>
            }

            @case (CellType.BooleanLabels) {
              <td mat-cell *matCellDef="let row" class="text-nowrap">
                {{ row[column.cellData] === true ? column.cellDataTrue : column.cellDataFalse }}
              </td>
            }

            @case (CellType.String) {
              <td mat-cell *matCellDef="let row" class="text-nowrap">
                <div class="cell">
                  @if (column.icon && checkIconVisibility(column, row)) {
                    <mat-icon>{{ getIcon(column, row) }}</mat-icon>
                  }
                  {{ row[column.cellData] }}
                </div>
              </td>
            }

            @case (CellType.StringBis) {
              <td mat-cell *matCellDef="let row">
                @if (row[column.cellData] && column.cellDataBis) {
                  {{ row[column.cellData][column.cellDataBis] }}
                } @else if (!column.allowCellEmpty) {
                  <ng-container *ngTemplateOutlet="loadTpl" />
                }
              </td>
            }

            @case (CellType.StringTer) {
              <td mat-cell *matCellDef="let row">
                @if (row[column.cellData] && column.cellDataBis && column.cellDataTer) {
                  {{ row[column.cellData][column.cellDataBis][column.cellDataTer] }}
                } @else if (!column.allowCellEmpty) {
                  <ng-container *ngTemplateOutlet="loadTpl" />
                }
              </td>
            }

            @case (CellType.StringSmall) {
              <td mat-cell *matCellDef="let row" class="text-nowrap text-small">
                <div class="cell">
                  @if (column.icon && checkIconVisibility(column, row)) {
                    <mat-icon>{{ getIcon(column, row) }}</mat-icon>
                  }
                  {{ row[column.cellData] }}
                </div>
              </td>
            }

            @case (CellType.Date) {
              <td mat-cell *matCellDef="let row" class="text-nowrap">
                {{ row[column.cellData] | date: column.dateFormat }}
              </td>
            }

            @case (CellType.Select) {
              <td mat-cell *matCellDef="let row">
                @if (column.select) {
                  <mat-select
                    [(value)]="row[column.cellData].id"
                    (selectionChange)="onChange(row)"
                    [disabled]="column.select.disabled && column.select.disabled(row)"
                    panelClass="select-panel"
                  >
                    @for (element of column.select.list; track element.id) {
                      <mat-option
                        [value]="element.id"
                        [disabled]="
                          column.select.hideOptionCondition && column.select.hideOptionCondition(row, element)
                        "
                      >
                        {{ element[column.select.identifier] }}
                      </mat-option>
                    }
                  </mat-select>
                }
              </td>
            }

            @case (CellType.Img) {
              <td mat-cell *matCellDef="let row">
                @if (row.logoName) {
                  <img
                    src="{{ column.cellData }}/{{ row.id }}/logo"
                    [alt]="row.logoName"
                    width="60em"
                    title="{{ row.logoName }}"
                  />
                } @else {
                  <em></em>
                }
              </td>
            }

            @case (CellType.Visibility) {
              <td mat-cell *matCellDef="let row">
                @if (row[column.cellData]) {
                  <mat-icon>lock_open</mat-icon>
                } @else {
                  <mat-icon>lock</mat-icon>
                }
              </td>
            }

            @case (CellType.Action) {
              <td mat-cell *matCellDef="let row" class="sticky-right" [style.minWidth]="column.minWidth">
                <ng-container *ngTemplateOutlet="rowActions; context: { $implicit: column.actions, row: row }" />
              </td>
            }
          }
        </ng-container>
      }

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: !generalActions?.stickyDisabled"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    @if (!dataSource.data.length) {
      <div class="empty-grid-text" i18n="No data">Aucune donnée à afficher.</div>
    }
  </div>
  @if (!paginatorDisabled) {
    <smv-paging [pagingInfo]="pagingInfo" (pageChange)="onRefresh()" />
  }
</div>

<ng-template #loadTpl i18n="Table|Loadind data">Récupération des données...</ng-template>

<ng-template #rowActions let-actions let-row="row">
  @for (action of actions; track action) {
    @if (!(action.hide && action.hideCondition && action.hideCondition(row))) {
      @switch (action.type) {
        @case (ActionType.Router) {
          <button mat-icon-button color="primary" [routerLink]="[action.link, row.id]" [matTooltip]="action.tooltip">
            <mat-icon>{{ action.icon }}</mat-icon>
          </button>
        }

        @case (ActionType.Menu) {
          <button
            mat-icon-button
            [matTooltip]="action.tooltip"
            [matMenuTriggerFor]="actionMenu"
            [matMenuTriggerData]="{ row: row, actions: action.menu }"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
        }

        @case (ActionType.Default) {
          <button
            mat-stroked-button
            (click)="onDefaultAction(row[action.identifier], action.key)"
            [matTooltip]="action.tooltip"
            matTooltipPosition="before"
          >
            <mat-icon>{{ action.icon }}</mat-icon>
            <span>{{ action.label }}</span>
          </button>
        }
      }
    }
  }
</ng-template>

<ng-template #rowMenuActions let-actions let-row="row">
  @for (action of actions; track action) {
    @if (!(action.hide && action.hideCondition && action.hideCondition(row))) {
      @switch (action.type) {
        @case (ActionType.Modify) {
          <button mat-menu-item (click)="onModify(row.id)" [matTooltip]="action.tooltip" matTooltipPosition="before">
            <mat-icon>edit_user</mat-icon>
            <span>{{ action.label }}</span>
          </button>
        }

        @case (ActionType.Delete) {
          <button
            mat-menu-item
            (click)="onDelete([row[action.identifier]], [row.id])"
            [matTooltip]="action.tooltip"
            matTooltipPosition="before"
          >
            <mat-icon>delete</mat-icon>
            <span>{{ action.label }}</span>
          </button>
        }

        @case (ActionType.Default) {
          <button
            mat-menu-item
            (click)="onDefaultAction(row[action.identifier], action.key)"
            [matTooltip]="action.tooltip"
            matTooltipPosition="before"
          >
            <mat-icon>{{ action.icon }}</mat-icon>
            <span>{{ action.label }}</span>
          </button>
        }

        @case (ActionType.Router) {
          <button mat-menu-item [routerLink]="[action.link, row.id]">
            <mat-icon>{{ action.icon }}</mat-icon>
            <span>{{ action.label }}</span>
          </button>
        }
      }
    }
  }
</ng-template>

<mat-menu #actionMenu="matMenu" xPosition="before">
  <ng-template matMenuContent let-actions="actions" let-row="row">
    <ng-container *ngTemplateOutlet="rowMenuActions; context: { $implicit: actions, row: row }" />
  </ng-template>
</mat-menu>
