<smv-stepper
  class="stepper"
  [steps]="steps"
  [(currentStep)]="currentStep"
  [(collapsed)]="stepperCollapsed"
  [ngClass]="{ collapsed: stepperCollapsed }"
/>

<div class="content">
  @switch (currentStep) {
    @case ('INFOS_FARM') {
      <smv-champ-propre-farm-info
        (farmInfoCompleteChange)="updateFarmInfoCompleted($event)"
      ></smv-champ-propre-farm-info>
    }
    @case ('INFOS_EQUIPMENT') {
      <smv-champ-propre-equipment-info
        (equipmentInfoCompleteChange)="updateEquipmentInfoCompleted($event)"
      ></smv-champ-propre-equipment-info>
    }
  }
</div>
