import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'smv-vigie-virose-legal-notices',
  standalone: true,
  imports: [CommonModule, MatDividerModule],
  templateUrl: './vigie-virose-legal-notices.component.html',
  styleUrls: ['./vigie-virose-legal-notices.component.scss'],
})
export class VigieViroseLegalNoticesComponent {}
