import { Sort } from '@angular/material/sort';
import { ApplicationModel } from '@core/model/application-api/application.model';
import { Customer } from '@core/model/customer.model';
import { Right } from '@core/model/right.model';
import { User } from '@core/model/user.model';
import { KeysOfType } from '@core/utils/general.utils';

export interface DefaultType<N extends number | string> {
  id: N;
}

export class CellType {
  static Checkbox = 'Checkbox';
  static String = 'String';
  static StringBis = 'StringBis';
  static StringTer = 'StringTer';
  static StringSmall = 'StringSmall';
  static Date = 'Date';
  static Boolean = 'Boolean';
  static BooleanLabels = 'BooleanLabels';
  static Img = 'Img';
  static Visibility = 'Visibility';
  static Select = 'Select';
  static Action = 'Action';
}

export class ActionType {
  static Router = 'Router';
  static Modify = 'Modify';
  static Delete = 'Delete';
  static Default = 'Default';
  static Menu = 'Menu';
}

export interface ChangeAction {
  application: ApplicationModel;
  customer: Customer;
  right: Right;
  user: User;
}

export interface SelectionAction<N> {
  identifiers: string[];
  ids: N[];
}

export interface DefaultAction {
  identifier: string;
  key: string;
}

export interface Task<T, N extends number | string> {
  selected: boolean;
  subtasks: Subtask<T, N>[];
}

export interface Subtask<T, N extends number | string> {
  id: N;
  identifier: string;
  selected: boolean;
  element: T;
}

export interface Action<T> {
  type: ActionType;
  key?: string;
  label?: string;
  link?: string;
  icon?: string;
  tooltip: string;
  identifier: string;
  hide?: boolean;
  hideCondition?: (element: T) => boolean;
  menu?: Action<T>[];
}

export interface Select<T, V> {
  identifier: KeysOfType<V, string>;
  list: V[];
  disabled?: (element: T) => boolean;
  hideOptionCondition?: (element: T, option: V) => boolean;
}

export interface GeneralActions<T> {
  tableOnly?: boolean;
  pageLayout?: boolean;
  stickyDisabled?: boolean;
  checkboxDisabled?: boolean;
  checkboxAllSelected?: boolean;
  hideCheckboxCondition?: (element: T) => boolean;
  refreshDisabled?: boolean;
  addDisabled?: boolean;
  addIcon?: string;
  addTooltip?: string;
  deleteSelectedDisabled?: boolean;
  deleteTooltip?: string;
  actionSelectedDisabled?: boolean;
  actionSelectedIcon?: string;
  actionSelectedTooltip?: string;
  rightActionDisabled?: boolean;
  rightActionIcon?: string;
  rightActionTooltip?: string;
  searchDisabled?: boolean;
  searchPlaceholder?: string;
  defaultSort?: Sort;
}

export interface Column<T, V> {
  field: string;
  label: string;
  type: CellType;
  cellData: string;
  cellDataBis?: string;
  cellDataTer?: string;
  allowCellEmpty?: boolean;
  actions?: Action<T>[];
  select?: Select<T, V>;
  sort?: boolean;
  hide?: boolean;
  helpText?: string;
  cellDataTrue?: string;
  cellDataFalse?: string;
  iconVisibility?: boolean | ((row: T) => boolean);
  icon?: string | ((row: T) => string);
  dateFormat?: string;
  minWidth?: string;
}
