<div class="smv-legal-dialog">
  <h1 mat-dialog-title>
    <ng-container i18n="Legal Mention">Conditions Générales d'utilisation du service</ng-container>
    {{ serviceName }}
  </h1>
  <mat-divider />

  <div mat-dialog-content>
    @switch (appType) {
      @case ('VIGIEVIROSE') {
        <smv-vigie-virose-legal-notices />
      }

      @case ('QUALIH2O') {
        <smv-quali-h2o-legal-notices />
      }

      @case ('CHAMP_PROPRE') {
        <smv-champ-propre-legal-notices />
      }

      @default {
        <smv-mapview-legal-notices />
      }
    }
  </div>
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close i18n="Button|Close">Fermer</button>
  </div>
</div>
