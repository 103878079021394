<div class="smv-legal-dialog">
  <h1 i18n="Privacy Policy" mat-dialog-title>Politique de Confidentialité du service MAPVIEW™</h1>

  <div mat-dialog-content>
    <span class="last-revision" i18n="Privacy Policy">Date de la version : 15/01/2024</span>

    <section>
      <h2 i18n="Privacy Policy">1. INTRODUCTION</h2>
      <p i18n="Privacy Policy">
        Nous nous réservons le droit de modifier la présente Politique de confidentialité à l’occasion du lancement de
        nouveaux Services ou de nouvelles versions des Services. Le cas échéant, nous vous demanderons de confirmer que
        vous avez lu et compris la nouvelle version de cette Politique de confidentialité avant que vous ne commenciez à
        utiliser la nouvelle version des Services ou les nouveaux Services en question.
      </p>
      <p i18n="Privacy Policy">
        Dans la présente Politique de confidentialité, nous employons certains termes qui sont définis. Ces termes
        commencent par une lettre majuscule. Ils ont la signification qui leur est donnée dans nos Conditions générales,
        lesquelles font partie intégrante du Contrat qui nous lie, vous et nous.
      </p>
      <p i18n="Privacy Policy">
        <span class="bold"> Responsable du traitement.</span> Le responsable du traitement est Syngenta France SA,
        société immatriculée sous le numéro 443 716 832, dont le siège social est sis 1228 chemin de l’Hobit, 31790
        Saint-Sauveur, France, ci-après « Syngenta », « nous », « notre », « nos ».
      </p>
      <p i18n="Privacy Policy">
        <span class="bold">Coordonnées.</span> Vous pouvez contacter notre Délégué à la protection des données par
        courriel à l’adresse dataprivacy.fr&#64;syngenta.com ou par courrier postal à l’adresse du siège social de
        Syngenta, qui est indiquée ci-dessus.
      </p>
      <p i18n="Privacy Policy">
        <span class="bold">Champ d’application.</span> La présente Politique de confidentialité couvre les données à
        caractère personnel qui sont traitées en lien avec l’utilisation de nos Services. Les données à caractère
        personnel sont des données qui portent sur vous, dans la mesure où vous êtes une personne physique identifiée ou
        identifiable.
      </p>
      <p i18n="Privacy Policy">
        Sous réserve de notre Contrat, nous ne vendons nos Services qu’à une clientèle d’entreprises. En conséquence,
        dès lors que vous êtes autorisé(e) à utiliser un Service au nom d’une Entreprise, à savoir un producteur
        agricole ou un Conseiller agronomique, nous traitons vos données à caractère personnel (pour de plus amples
        informations, voir nos Conditions générales).
      </p>
    </section>

    <section>
      <h2 i18n="Privacy Policy">2. Traitement de données à caractère personnel :</h2>
      <p i18n="Privacy Policy">
        Dans le cas où nous collectons et utilisons vos données à caractère personnel pour nos propres finalités, qui
        sont indiquées dans le tableau ci-dessous, Syngenta est le « responsable du traitement ». Toutefois, pour les
        autres activités de traitement des données, le responsable du traitement est l’Entreprise (et Syngenta est le
        sous-traitant).
      </p>
      <p i18n="Privacy Policy">
        <span class="bold"> Syngenta en tant que sous-traitant.</span> Nous attirons votre attention sur le fait que,
        dès lors que vous utilisez un Service en tant qu’utilisateur autorisé au nom d’une Entreprise, le responsable du
        traitement, pour les données à caractère personnel que vous pouvez communiquer au cours de l’utilisation du
        Service en question (y compris dans le cadre de Vos Données entrantes), est l’Entreprise. Nous sommes le
        sous-traitant dès lors que nous utilisons les données à caractère personnel que vous communiquez pour fournir le
        service, par exemple pour fournir à l’Entreprise un appui et une aide à la prise de décisions agronomiques ou
        d’autres Données de sortie.
      </p>
      <p i18n="Privacy Policy">
        Par exemple, certains Services vous permettent de saisir des informations sur le lieu où vous vous trouvez et
        des observations de plein champ, ou de charger des images. Nous utilisons l’ensemble de ces informations en tant
        que sous-traitant pour vous fournir le service dont le Service permet de bénéficier et pour produire les Données
        de sortie pour l’Entreprise (notre « client »).<span class="bold">
          Pour de plus amples informations sur ce traitement, y compris pour faire valoir vos droits, consultez la ou
          les politiques de confidentialité de l’Entreprise (responsable du traitement).</span
        >
      </p>
      <table>
        <tr>
          <th colspan="3" i18n="Privacy Policy">Activités de Syngenta en tant que responsable du traitement</th>
        </tr>
        <tr>
          <th i18n="Privacy Policy">Type de données à caractère personnel</th>
          <th i18n="Privacy Policy">Finalités</th>
          <th i18n="Privacy Policy">Bases légales</th>
        </tr>
        <tr>
          <td i18n="Privacy Policy">
            Vos noms, adresse électronique, raison sociale et le mot de passe de votre Compte.
          </td>
          <td i18n="Privacy Policy">
            Nous utilisons ces données à caractère personnel pour vous enregistrer et créer votre Compte et pour vous
            fournir les Services.
          </td>
          <td i18n="Privacy Policy">
            Dans le cas où vous contractez avec nous en qualité de personne physique, par exemple en qualité
            d’entreprise individuelle, la base légale est l’exécution du contrat.<br /><br />Dans le cas où votre
            société ou autre personne morale contracte avec nous, la base légale est l’exécution du contrat conclu avec
            vous lorsque vous acceptez nos conditions d’utilisation en ligne, ainsi que notre intérêt légitime à créer
            des comptes de manière à nous assurer qu’un utilisateur est autorisé et à faire la distinction entre les
            utilisateurs / appareils.
          </td>
        </tr>
        <tr>
          <td i18n="Privacy Policy">Vos noms, adresse électronique et raison sociale.</td>
          <td i18n="Privacy Policy">
            Nous utilisons ces données à caractère personnel pour communiquer avec vous et vous envoyer des
            notifications concernant la mise à niveau de nos Services par courriel, par message texte ou sur nos applis,
            ou d’autres messages importants concernant le fonctionnement du service.
          </td>
          <td i18n="Privacy Policy">
            Notre intérêt légitime à vous tenir informé(e)s sur des thématiques liées à l'utilisation de nos Services.
          </td>
        </tr>
        <tr>
          <td i18n="Privacy Policy">
            Vos noms, adresse électronique, raison sociale et le mot de passe de votre Compte.
          </td>
          <td i18n="Privacy Policy">
            Nous utilisons ces données à caractère personnel pour vous apporter une assistance technique et un service
            client.
          </td>
          <td i18n="Privacy Policy">
            Dans le cas où vous contractez avec nous en qualité de personne physique, par exemple en qualité
            d’entreprise individuelle, la base légale est l’exécution du contrat (en particulier apporter une réponse à
            vos questions et problèmes).<br /><br />Dans le cas où votre société ou autre personne morale contracte avec
            nous, la base légale est l’exécution du contrat conclu avec vous lorsque vous acceptez nos conditions
            d’utilisation en ligne, ainsi que notre intérêt légitime à apporter une réponse aux questions et problèmes
            de nos utilisateurs.
          </td>
        </tr>
        <tr>
          <td i18n="Privacy Policy">Vos noms, adresse électronique, raison sociale et numéro de téléphone.</td>
          <td i18n="Privacy Policy">
            Nous utilisons ces données à caractère personnel pour adresser des enquêtes de satisfaction à nos
            utilisateurs et leur demander leur avis sur nos Services.
          </td>
          <td i18n="Privacy Policy">
            Notre intérêt légitime à améliorer les Services pour vous et pour nos futurs utilisateurs.
          </td>
        </tr>
        <tr>
          <td i18n="Privacy Policy">
            Si vous utilisez un service en ligne en lien avec un Service, les informations suivantes seront collectées :
            type d’ordinateur (Windows ou Macintosh), nom et version du système d’exploitation, langue, type et version
            du navigateur et nom et version des services en ligne que vous utilisez, adresse IP, page(s) consultée(s),
            date et heure de la consultation.
          </td>
          <td i18n="Privacy Policy">
            Nous utilisons ces informations pour fournir les Services à nos utilisateurs, pour garantir qu’ils
            fonctionnent correctement, pour calculer le niveau d’utilisation, pour nous aider à diagnostiquer les
            problèmes liés au serveur, aux fins des statistiques d’utilisation de nos services en ligne et à des fins de
            sécurité.
          </td>
          <td i18n="Privacy Policy">
            Notre intérêt légitime à : (i) comprendre comment nos services en ligne sont utilisés afin de les améliorer
            ; et (ii) identifier et lutter contre les menaces qui pèsent sur la sécurité de nos applis et de nos
            utilisateurs
          </td>
        </tr>
        <tr>
          <td i18n="Privacy Policy">Coordonnées et informations sur la manière dont vous utilisez les Services.</td>
          <td i18n="Privacy Policy">
            Établissement de profils clients et prospection commerciale - nous traitons ces données (ainsi que d’autres
            informations que vous nous avez communiquées en utilisant d’autres Services ou services de Syngenta) afin de
            comprendre la manière dont vous avez utilisé les Services et quels sont vos besoins agronomiques. Nous
            pouvons ensuite vous contacter (en tant que représentant de votre Entreprise) afin de vous communiquer des
            informations sur d’autres Services et services de Syngenta que nous estimons susceptibles de vous
            intéresser. Si la loi en vigueur l’exige, nous vous demanderons d’accepter la prospection commerciale et,
            dans tous les cas, vous aurez la possibilité d’y mettre fin à tout moment en nous contactant de la manière
            indiquée ci-dessous ou en utilisant le lien de désabonnement qui figure dans nos courriels.
          </td>
          <td i18n="Privacy Policy">
            Notre intérêt légitime à commercialiser nos Services et services.<br /><br />
            Consentement, dans le cas où il est requis par la loi en vigueur pour la prospection commerciale.
          </td>
        </tr>
        <tr>
          <td i18n="Privacy Policy">
            Nom, identifiants spécifiques de messagerie et de l’appareil, type d’ordinateur (Windows ou Macintosh), nom
            et version du système d’exploitation, langue, type et version du navigateur Internet et nom et version des
            services en ligne que vous utilisez, adresse IP, page(s) consultée(s), date et heure de la consultation.
          </td>
          <td i18n="Privacy Policy">
            Nous utilisons des « cookies » et d’autres technologies similaires pour collecter des informations et
            supporter certaines fonctionnalités de nos Services.<br /><br />Nous utilisons ces informations pour fournir
            les Services à nos utilisateurs, pour la maintenance, pour garantir que les Services fonctionnent
            correctement, pour calculer le niveau d’utilisation, aux fins des statistiques d’utilisation de nos Services
            et services en ligne afin d’améliorer le fonctionnement de ces derniers, pour nous aider à diagnostiquer les
            problèmes liés au serveur, pour comprendre comment nos Services sont utilisés, pour répondre aux questions
            concernant leur utilisation et à des fins de sécurité.
          </td>
          <td i18n="Privacy Policy">
            La base légale des activités de traitement associées aux cookies nécessaires réside dans notre intérêt
            légitime à fournir nos Services à nos utilisateurs.<br /><br />Le fondement juridique des activités de
            traitement associées aux cookies non nécessaires réside dans votre consentement.
          </td>
        </tr>
      </table>
    </section>

    <section>
      <h2 i18n="Privacy Policy">3. Comment transférons-nous vos données à caractère personnel ?</h2>
      <h3 i18n="Privacy Policy">Transferts entre sociétés</h3>
      <p i18n="Privacy Policy">
        Vos données à caractère personnel peuvent être transférées à une société qui rachète ou envisage de racheter le
        stock ou l’actif de Syngenta, de sa Société mère, de l’une de ses filiales ou l’une de nos entreprises (ou de
        ses conseillers, prêteurs ou auditeurs respectifs), ou encore qui investit dans l’une de ces sociétés, par
        exemple à la suite d’une cession, d’une fusion, d’une restructuration ou d’une liquidation. La base légale de ce
        traitement des données réside dans l’intérêt légitime que nous avons à être en mesure de céder ou d’acquérir un
        actif au sein de notre entreprise. Dans le cas d’un transfert de cette nature, l’utilisation par l’acquéreur de
        vos données à caractère personnel reste soumise à la présente Politique de confidentialité et aux préférences
        que vous nous avez indiquées en matière de confidentialité.
      </p>
    </section>

    <section>
      <h3 i18n="Privacy Policy">Respect des lois et protection de nos droits et des droits d’autrui</h3>
      <p i18n="Privacy Policy">
        Nous pouvons communiquer des données à caractère personnel si cela s’avère nécessaire pour être en règle avec la
        loi, exécuter une décision d’un tribunal ou obéir à une demande d’un régulateur ou à une citation à comparaître.
        Si le droit français, le droit communautaire ou le droit d’un État membre prévoit des obligations, ce traitement
        des données a pour base légale la nécessité de remplir une obligation légale à laquelle nous sommes soumis (en
        tant que responsable du traitement). Avant de communiquer des données à caractère personnel aux pouvoirs publics
        de pays tiers ou de mettre des données à caractère personnel à leur disposition, nous examinerons nos
        obligations d’assurer un niveau de protection des données adapté, sauf dans le cas où des dérogations peuvent
        s’appliquer.
      </p>
      <p i18n="Privacy Policy">
        Nous pouvons également communiquer des données à caractère personnel pour empêcher qu’un crime ne soit commis ou
        enquêter sur un crime, comme par exemple une fraude ou un vol d’identité (la base légale étant que nous avons un
        intérêt légitime à nous protéger contre la criminalité et à faire valoir ou protéger nos droits) ; pour faire
        exécuter ou appliquer nos conditions d’utilisation en ligne ou toute autre relation contractuelle avec vous (la
        base légale étant la nécessité d’exécuter un contrat conclu avec vous (dans le cas où nous contractons avec vous
        en tant qu’entreprise individuelle) ou encore que nous avons un intérêt légitime à le faire) ; ou pour protéger
        nos droits ou nos actifs ou les droits, le patrimoine et la sécurité de nos utilisateurs ou d’autrui (la base
        légale étant notre intérêt légitime à le faire).
      </p>
    </section>

    <section>
      <h2 i18n="Privacy Policy">4. Comment exercer vos droits concernant vos données à caractère personnel ?</h2>
      <p i18n="Privacy Policy">
        Nous nous efforçons d’observer une grande transparence en ce qui concerne les données que nous traitons.
        S’agissant du traitement de vos données à caractère personnel que nous décrivons dans la présente Politique de
        confidentialité, vous disposez des droits suivants :
      </p>
      <ul class="bullet-list">
        <li i18n="Privacy Policy">
          Obtenir confirmation que Syngenta traite vos données à caractère personnel, avoir accès aux données à
          caractère personnel que nous détenons vous concernant ou en obtenir une copie ;
        </li>
        <li i18n="Privacy Policy">
          Nous demander de corriger ou de mettre à jour toutes données à caractère personnel inexactes ou de compléter
          les données à caractère personnel incomplètes ;
        </li>
        <li i18n="Privacy Policy">Nous demander de supprimer ou d’effacer vos données à caractère personnel ;</li>
        <li i18n="Privacy Policy">Limiter le traitement de vos données à caractère personnel ;</li>
        <li i18n="Privacy Policy">
          Nous demander de vous transmettre certaines de vos données à caractère personnel ou de les transférer ou faire
          transférer à un autre responsable du traitement (portabilité des données) ; et
        </li>
        <li i18n="Privacy Policy">
          Vous opposer au traitement de vos données à caractère personnel en vous fondant sur votre intérêt légitime. Si
          nous donnons suite à votre opposition, alors, sous réserve de tout autre intérêt légitime que nous serions en
          mesure d’invoquer (par exemple dans le cadre d’actions en justice en cours ou possibles à notre encontre),
          nous ne serons alors plus autorisés à traiter vos données à caractère personnel ;
        </li>
        <li i18n="Privacy Policy">
          Nous demander de cesser de traiter vos données à caractère personnel à des fins de prospection commerciale.
        </li>
        <li i18n="Privacy Policy">
          Nous fournir des instructions sur le sort de vos données personnelles après votre décès.
        </li>
        <li i18n="Privacy Policy">
          Retirer à tout moment le consentement que vous avez donné sans que cela n’affecte la licéité du traitement
          effectué sur la base de ce consentement jusqu’à son retrait.
        </li>
      </ul>
      <p i18n="Privacy Policy">
        Certains droits sont soumis à des restrictions ou limitations et, selon le fondement juridique sur lequel nous
        nous appuyons pour traiter vos données à caractère personnel, vous pouvez disposer ou non de ces droits (voir
        l’article 2 ci-dessus). En outre, nous pouvons nous prévaloir des dispenses prévues par le droit Français, le
        droit communautaire ou le droit d’un État membre ou tout autre droit applicable afin de rejeter tout ou partie
        de votre demande. Dans ce cas, nous vous en informerons dans notre réponse à votre demande.
      </p>
      <p i18n="Privacy Policy">
        Si vous souhaitez exercer l’un des droits ci-dessus, vous pouvez nous contacter de la manière suivante :
      </p>
      <p i18n="Privacy Policy">
        <span class="bold">Si vous résidez en France,</span> vous pouvez contacter notre Délégué à la protection des
        données par courriel à l’adresse dataprivacy.fr&#64;syngenta.com ou par courrier postal à l’adresse de Syngenta
        qui est indiquée dans l’article Introduction, tout au début de la présente Politique de confidentialité.
      </p>
      <p i18n="Privacy Policy">
        Nous répondrons à toute demande raisonnablement exposée dans les meilleurs délais une fois que nous l’aurons
        validée.
      </p>
    </section>

    <section>
      <h2 i18n="Privacy Policy">
        5. Comment supprimer votre Compte ? Pendant combien de temps stockons-nous vos données à caractère personnel ?
      </h2>
      <p i18n="Privacy Policy">
        Sous réserve des principes ci-dessous, nous entendons continuer de stocker vos données à caractère personnel
        tant que vous avez un Compte. Si, pendant soixante mois, vous n’utilisez pas les Services, Syngenta supprimera
        votre Compte, à moins que les lois en vigueur ne nous obligent à conserver les données plus longtemps.
      </p>
      <p i18n="Privacy Policy">
        Le Partenaire a la garantie de pouvoir accéder à ses données pendant la Période d'abonnement définie dans les
        Conditions commerciales de l’offre. Au terme de cette Période d'abonnement, l'accès est clos sauf si le
        Partenaire reconduit son abonnement. Toutefois, le Partenaire a le droit d'exiger à tout moment la suppression
        de son compte et de ses données à caractère personnel.
      </p>
      <p i18n="Privacy Policy">
        Nous attirons votre attention sur le fait que la suppression des Services sur l’application web n’entraînera pas
        automatiquement la suppression de votre Compte. Votre Compte restera actif jusqu’à ce que nous demandiez de le
        supprimer par courriel. Sous réserve des principes ci-dessous, la suppression de votre Compte entraînera
        également la suppression de vos données à caractère personnel. Toutefois, nous pouvons conserver des données
        dénuées de caractère personnel après la suppression de votre Compte.
      </p>
      <p i18n="Privacy Policy">
        Aux fins de l’établissement de preuves, notamment pour nous préparer à faire face aux litiges ou plaintes, nous
        pouvons, une fois que vous avez supprimé votre Compte, continuer de stocker certaines données à caractère
        personnel (comme par exemple votre identité et le fait que vous avez utilisé nos Services) aussi longtemps que
        cela s’avère nécessaire et autorisé compte tenu des délais de prescription en vigueur dans votre pays. La durée
        de conservation peut ainsi varier selon la situation. Toutefois, si tel est le cas, nous ne traiterons plus
        activement les données à caractère personnel pour les finalités prévues initialement mais uniquement aux fins
        mentionnées ci-dessus. De plus, nous limiterons tout accès à vos données à caractère personnel basé sur ce
        fondement. Le fondement juridique de ce traitement est qu’il est nécessaire pour notre intérêt légitime à faire
        constater, exercer ou défendre nos droits. Si nécessaire, nous pouvons également conserver vos données à
        caractère personnel après la suppression de votre Compte pour remplir une obligation légale
      </p>
    </section>

    <section>
      <h2 i18n="Privacy Policy">
        6. Questions, interrogations et plaintes concernant nos pratiques en matière de protection de la vie privée.
      </h2>
      <p i18n="Privacy Policy">
        Pour toute question, vous pouvez contacter notre Délégué à la protection des données par courriel à l’adresse
        dataprivacy.fr&#64;syngenta.com ou par courrier postal à l’adresse de Syngenta qui est indiquée dans l’article
        Introduction, tout au début de la présente Politique de confidentialité. Vous avez également le droit
        d’introduire une plainte auprès de l’autorité de contrôle compétente. En France, il s’agit de la CNIL
        (coordonnées disponibles à l’adresse http://cnil.fr).
      </p>
    </section>
  </div>

  <div mat-dialog-actions>
    <button mat-button mat-dialog-close i18n="Button|Close">Fermer</button>
  </div>
</div>
