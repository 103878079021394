import { ApplicationModel } from './application-api/application.model';
import { Customer } from './customer.model';
import { ProfileCode } from './right.model';

export type AdminProfile = 'NONE' | 'SUPER' | 'USER';

export interface UserWrapper {
  User: UserModel;
}

export interface UserModel {
  id: number;
  username: string;
  nom: string;
  prenom: string;
  email: string;
  groupName?: string;
  role?: string;
  adminProfile: AdminProfile;
  slfcDriven: boolean;
  syngenta: boolean;
  customers: Customer[];
  authorizedMapCreation: boolean;
  authorizedSimulationVV: boolean;
  authorizedSimulationIcare: boolean;
  sessionAuthKey: string;
  country: string;
  passwordUndefined: boolean;
}

export interface SimpleUser {
  id: number;
  nom: string;
  prenom: string;
  email: string;
}

export interface NewUser {
  nom: string;
  prenom: string;
  email: string;
  groupName?: string;
  role?: string;
  password: string;
  adminProfile: AdminProfile;
  slfcDriven: boolean;
  authorizedMapCreation: boolean;
}

export class User implements UserModel {
  id: number;
  username: string;
  nom: string;
  prenom: string;
  email: string;
  groupName?: string;
  role?: string;
  adminProfile: AdminProfile;
  slfcDriven: boolean;
  syngenta: boolean;
  customers: Customer[];
  authorizedMapCreation: boolean;
  authorizedSimulationVV: boolean;
  authorizedSimulationIcare: boolean;
  sessionAuthKey: string;
  country: string;
  passwordUndefined: boolean;

  constructor(user: UserModel) {
    this.id = user.id;
    this.username = user.username;
    this.nom = user.nom;
    this.prenom = user.prenom;
    this.email = user.email;
    this.groupName = user.groupName;
    this.role = user.role;
    this.adminProfile = user.adminProfile;
    this.slfcDriven = user.slfcDriven;
    this.syngenta = user.syngenta;
    this.customers = user.customers;
    this.authorizedMapCreation = user.authorizedMapCreation;
    this.authorizedSimulationVV = user.authorizedSimulationVV;
    this.authorizedSimulationIcare = user.authorizedSimulationIcare;
    this.sessionAuthKey = user.sessionAuthKey;
    this.country = user.country;
    this.passwordUndefined = user.passwordUndefined;
  }

  isOwner(application: ApplicationModel): boolean {
    return this.id === application.idProprietaire;
  }

  hasProfile(application: ApplicationModel, profile: ProfileCode | ProfileCode[]): boolean {
    const profiles = Array.isArray(profile) ? profile : [profile];
    return profiles.includes(application.currentUserProfil?.code ?? ProfileCode.LECTEUR);
  }

  canEdit(application: ApplicationModel, allowedProfiles: ProfileCode | ProfileCode[]): boolean {
    return this.isSuperAdmin() || this.isOwner(application) || this.hasProfile(application, allowedProfiles);
  }

  isSuperAdmin() {
    return this.adminProfile === 'SUPER';
  }

  isAdmin() {
    return this.adminProfile !== 'NONE';
  }
}
