<div id="sidemenu-title">MAPVIEW™</div>

<mat-list>
  <div>
    @for (menu of menuOptions; track menu.label) {
      @if (
        (!menu.superAdminOnly && !menu.adminOnly) || (menu.adminOnly && (isAdmin$ | async)) || (isSuperAdmin$ | async)
      ) {
        <ng-container *ngTemplateOutlet="menuItem; context: { $implicit: menu }" />
      }
    }
  </div>

  <!-- Bottom section -->
  <div>
    <mat-divider />
    <mat-nav-list>
      <a mat-list-item href="mailto:map.view@syngenta.com" i18n="Links|Link to contact page">Contact</a>
    </mat-nav-list>
    @for (menu of bottomMenuOptions; track menu.label) {
      <ng-container *ngTemplateOutlet="menuItem; context: { $implicit: menu }" />
    }
    <mat-divider />
    <mat-list-item
      routerLink="/compte"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      (click)="sendCloseSideNav()"
    >
      <mat-icon matListItemIcon>account_circle</mat-icon>
      <ng-container i18n="Sidenav">Mon compte</ng-container>
    </mat-list-item>
    <mat-list-item (click)="authService.logOut()">
      <mat-icon matListItemIcon>logout</mat-icon>
      <ng-container i18n="Sidenav">Déconnexion</ng-container>
    </mat-list-item>
  </div>
</mat-list>
<div id="sidemenu-footer">
  <span>©{{ copyrightYear }} Syngenta</span>
  <span>v{{ smvVersion }}</span>
</div>

<ng-template #menuItem let-menu>
  @if (menu.popup) {
    <mat-list-item (click)="openPopup(menu)">
      {{ menu.label }}
    </mat-list-item>
  } @else {
    <mat-list-item
      [routerLink]="menu.path"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      (click)="sendCloseSideNav()"
    >
      {{ menu.label }}
    </mat-list-item>
  }
</ng-template>
