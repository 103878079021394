<smv-large-background-page>
  <h1 i18n>Applications accessibles</h1>

  @if (currentUser?.authorizedMapCreation || currentUser?.isSuperAdmin()) {
    <button header-actions routerLink="/applications" mat-flat-button color="primary" i18n>
      Créer et modifier des applications
    </button>
  }

  <div page-content class="application-list">
    @for (application of applications$ | async; track application) {
      <mat-card class="application" [routerLink]="['/applications', application.id]">
        <mat-card-title class="header">
          <div class="header-title">
            @if (currentUser?.id === application.idProprietaire) {
              <div matTooltip="Vous êtes propriétaire de l'application" i18n-matTooltip>
                <mat-icon>co_present</mat-icon>
              </div>
            }
            {{ application.nom }}
          </div>
          @if (!application.publique) {
            <div matTooltip="Cette application est privée" i18n-matTooltip class="private-app-icon">
              <mat-icon>lock</mat-icon>
            </div>
          }
        </mat-card-title>
        <mat-divider />
        <mat-card-content class="content">
          @if (application.logoName) {
            <!-- Wrap img because of iOS having issues with flex + fit-content -->
            <div class="logo">
              <img
                src="{{ baseUrl }}/{{ application.id }}/logo"
                [alt]="application.logoName"
                matTooltip="{{ application.logoName }}"
              />
            </div>
          }
          @if (application.description) {
            <div class="description">{{ application.description }}</div>
          }
        </mat-card-content>
      </mat-card>
    }
  </div>
</smv-large-background-page>
