import { Component } from '@angular/core';
import { StepperComponent } from '@components/stepper/stepper.component';
import { StepGroup } from '@components/stepper/stepper.model';
import { FarmInfoComponent } from './farm-info/farm-info.component';
import { EquipmentInfoComponent } from './equipment-info/equipment-info.component';
import { AuthService } from '@core/services/auth.service';
import { ChampPropreService } from '@core/services/champ-propre.service';
import { NgClass } from '@angular/common';

@Component({
  selector: 'smv-champ-propre-stepper',
  standalone: true,
  imports: [StepperComponent, FarmInfoComponent, EquipmentInfoComponent, NgClass],
  templateUrl: './champ-propre-stepper.component.html',
  styleUrl: './champ-propre-stepper.component.scss',
})
export class ChampPropreStepperComponent {
  public userMail = this.authService.getUserEmailInSync() || '';
  public stepperCollapsed = false;

  public steps: StepGroup[] = [
    {
      groupName: $localize`:Champ Propre|Stepper:Gestion de mon exploitation`,
      steps: [
        {
          icon: 'apartment',
          text: $localize`:Champ Propre|Stepper:Informations de l'exploitation`,
          code: 'INFOS_FARM',
        },
        { icon: 'handyman', text: $localize`:Champ Propre|Stepper:Informations matériel`, code: 'INFOS_EQUIPMENT' },
        {
          icon: 'map',
          text: $localize`:Champ Propre|Stepper:Sélection des parcelles`,
          code: 'SELECTION_PARCELLES',
        },
      ],
    },
    {
      groupName: $localize`:Champ Propre|Stepper:Auto-évaluation parcellaire`,
      steps: [
        {
          icon: 'agriculture',
          text: $localize`:Champ Propre|Stepper:Informations à la parcelle`,
          code: 'INFOS_PARCELLES',
          disabled: true,
        },
        {
          icon: 'grass',
          text: $localize`:Champ Propre|Stepper:Panier adventices`,
          code: 'ADVENTICES',
          disabled: true,
        },
      ],
    },
    {
      groupName: $localize`:Champ Propre|Stepper:Recommandation de programme`,
      steps: [
        { icon: 'thumb_up', text: $localize`:Champ Propre|Stepper:Météo et planning`, code: 'METEO', disabled: true },
      ],
    },
  ];
  public currentStep = 'INFOS_FARM';

  constructor(
    private readonly champPropreService: ChampPropreService,
    private readonly authService: AuthService
  ) {
    this.champPropreService.getFarm(this.userMail).subscribe((farm) => {
      this.updateFarmInfoCompleted(this.champPropreService.isFarmInfoCompleted(farm));
      this.updateEquipmentInfoCompleted(this.champPropreService.isEquipmentInfoCompleted(farm));
    });
  }

  public updateFarmInfoCompleted(isCompleted: boolean): void {
    this.steps[0].steps[0].completed = isCompleted;
  }

  public updateEquipmentInfoCompleted(isCompleted: boolean): void {
    this.steps[0].steps[1].completed = isCompleted;
  }
}
