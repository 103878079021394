import { booleanAttribute, Component, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'smv-large-background-page',
  standalone: true,
  imports: [MatButtonModule, MatDividerModule, RouterModule],
  templateUrl: './large-background-page.component.html',
  styleUrl: './large-background-page.component.scss',
})
export class LargeBackgroundPageComponent {
  public canAccessContent = input(true, { transform: booleanAttribute });
}
