export interface Farm {
  name?: string;
  siret?: string;
  address?: string;
  zipCode?: string;
  city?: string;
  etaPossible?: boolean;
  gpsSprayer?: boolean;
  rowSprayer?: boolean;
  brandConsole?: BrandConsole;
  modelConsole?: ModelConsole;
  equipment?: Equipment;
  specificationsBook?: SpecificationsBook;
  otherSpecificationsBook?: string;
  yearConsole?: number;
  sectionWidth?: number;
}

export interface Dictionnary {
  id: string;
  label: string;
}

export interface UserPosition extends Dictionnary {}

export interface BrandConsole extends Dictionnary {}

export interface ModelConsole extends Dictionnary {
  brandConsole: BrandConsole;
}

export interface Equipment extends Dictionnary {}

export interface SpecificationsBook extends Dictionnary {}

export enum Section {
  'FARM',
  'EQUIPMENT',
}
