<div [hidden]="panelHide">
  <mat-card class="side-panel">
    <mat-card-title>
      <div>
        <span i18n>Service Eudémis</span>
        <button mat-icon-button (click)="displayed.emit()">
          <mat-icon>keyboard_double_arrow_right</mat-icon>
        </button>
      </div>
    </mat-card-title>

    <mat-card-content>
      <smv-icare-filter />

      <smv-customer-filter [appId]="applicationId" />

      <smv-statistic [allTrapLayerCode]="allTrapLayerCode" />

      @if (canImport) {
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header i18n>Import relevés</mat-expansion-panel-header>
          <smv-upload-file [url]="'/icare/datum/' + applicationId" accept=".csv" />
        </mat-expansion-panel>

        <mat-expansion-panel expanded>
          <mat-expansion-panel-header i18n>Rapport</mat-expansion-panel-header>
          <smv-date-select [appId]="applicationId" />
        </mat-expansion-panel>
      }
    </mat-card-content>
  </mat-card>
</div>

@if (isSheetDisplayed) {
  <smv-icare-sheet />
}
