import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { ApplicationDefinition } from '@assets/applications/applications';
import { ApplicationApiService } from '@core/services/api/application-api.service';
import { QualiH2OLegalNoticesComponent } from './quali-h2o-legal-notices/quali-h2o-legal-notices.component';
import { VigieViroseLegalNoticesComponent } from './vigie-virose-legal-notices/vigie-virose-legal-notices.component';
import { ChampPropreLegalNoticesComponent } from './champ-propre-legal-notices/champ-propre-legal-notices.component';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MapviewLegalNoticesComponent } from './mapview-legal-notices/mapview-legal-notices.component';

@Component({
  selector: 'smv-legal-notices',
  standalone: true,
  imports: [
    MatDialogModule,
    MatDividerModule,
    MatButtonModule,
    MapviewLegalNoticesComponent,
    VigieViroseLegalNoticesComponent,
    QualiH2OLegalNoticesComponent,
    ChampPropreLegalNoticesComponent,
  ],
  templateUrl: './legal-notices.component.html',
  styleUrls: ['./legal-notices.component.scss'],
})
export class LegalNoticesComponent {
  public appType = '';
  public serviceName = 'MAPVIEW™';

  private readonly customLegalNotices = ['VIGIEVIROSE', 'QUALIH2O', 'CHAMP_PROPRE'];

  constructor(
    private readonly applicationService: ApplicationApiService,
    @Inject(MAT_DIALOG_DATA) data: { appId?: number }
  ) {
    const currentApp = this.applicationService.currentApplication.getValue();
    if (Number(data?.appId) === currentApp?.id) {
      const appDefinition = ApplicationDefinition.appArray.find(
        (app) => app.id === currentApp?.functionnalityConfig.code
      );
      if (appDefinition && this.customLegalNotices.includes(appDefinition.id)) {
        this.appType = appDefinition.id;
        this.serviceName = appDefinition.name;
      }
    }
  }
}
