import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

import { FormFieldWrapperComponent } from '@components/form/form-field-wrapper/form-field-wrapper.component';
import { Farm, Section } from '@core/model/champ-propre.model';
import { AuthService } from '@core/services/auth.service';
import { ChampPropreService } from '@core/services/champ-propre.service';
import { NotificationService } from '@core/services/notification.service';

const modules = [
  CommonModule,
  FormFieldWrapperComponent,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatFormFieldModule,
  ReactiveFormsModule,
  MatInputModule,
  MatSelectModule,
];

@Component({
  selector: 'smv-champ-propre-farm-info',
  standalone: true,
  imports: modules,
  templateUrl: './farm-info.component.html',
  styleUrl: './farm-info.component.scss',
})
export class FarmInfoComponent {
  @Output() farmInfoCompleteChange: EventEmitter<boolean> = new EventEmitter();

  public userMail = this.authService.getUserEmailInSync() || '';
  public farm: Farm | undefined;

  public form = this.formBuilder.group({
    name: ['' as string],
    siret: ['' as string, { validators: [Validators.pattern(/^\d{14}$/)] }],
    address: ['' as string],
    zipCode: ['' as string],
    city: ['' as string],
  });

  constructor(
    private readonly champPropreService: ChampPropreService,
    private readonly notificationService: NotificationService,
    private readonly authService: AuthService,
    private formBuilder: NonNullableFormBuilder
  ) {
    this.champPropreService.getFarm(this.userMail).subscribe({
      next: (farm) => {
        this.farm = farm;
        this.form.patchValue(farm);
      },
    });
  }

  save(): void {
    const farm = this.form.getRawValue();
    if (!this.userMail) {
      this.notificationService.error($localize`Vous n'êtes pas connecté.`);
      return;
    }
    this.champPropreService.saveFarmInfo(farm, this.userMail, Section.FARM).subscribe({
      next: () => {
        this.notificationService.success($localize`Enregistré avec succès.`);
        this.farmInfoCompleteChange.emit(this.champPropreService.isFarmInfoCompleted(farm));
      },
      error: () => {
        this.notificationService.error($localize`Erreur lors de l'enregistrement.`);
      },
    });
  }
}
