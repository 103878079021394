<p i18n="Legal Mention">
  Quali’H2O Territoire est un outil pour les professionnels agricoles. Il constitue une aide à la prise de décision. Les
  contenus de Quali’H2O Territoire sont publiés à titre d’information, à l’exclusion de toute garantie sur leur
  exactitude, leur exhaustivité ou leur adéquation aux besoins spécifiques de l’utilisateur. Il appartient à
  l’utilisateur de vérifier par lui-même les données qui pourraient avoir une incidence sur sa décision. Les références
  à des produits, matières actives, spécialités commerciales phytosanitaires qui pourraient être citées du fait de
  l’utilisation de Quali’H2O Territoire par un professionnel ne constituent pas une recommandation de Syngenta France.
  Syngenta France décline toute responsabilité quant aux préconisations qui pourraient en découler.
</p>

<p i18n="Legal Mention">
  En poursuivant votre navigation sur ce site, vous acceptez l'utilisation de traceurs (cookies). Ceux-ci permettent au
  cours de votre session d’enregistrer des informations relatives à la navigation sur notre site que nous pourrons
  utiliser lors de vos visites ultérieures.
</p>
<p>
  <span i18n="Legal Mention">
    Conformément à la Loi Informatique et Libertés 78-17 du 6 janvier 1978 renforcée par le Règlement Général sur le
    Protection des Données du 27 avril 2016, vous disposez d’un droit d’accès, de rectification et de suppression des
    informations vous concernant. Vous pouvez exercer vos droits à tout moment, en faisant la demande directement par
    courrier auprès de : Syngenta France SAS-Direction Marketing, A l’attention du Webmaster-1 avenue des Près CS 10537
    - 78286 Guyancourt cedex ou en adressant un courrier électronique à
  </span>
  <a href="mailto:dataprivacy.fr@syngenta.com">dataprivacy.fr&#64;syngenta.com</a>.
</p>
<p i18n="Legal Mention">
  Syngenta France SAS, 12 Chemin de l’Hobit 31790 SAINT SAUVEUR- N° d’agrément MP02249 ©Marque déposée du groupe
  Syngenta.
</p>
<p>
  <b>
    <span i18n="Legal Mention">
      Avant utilisation, assurez-vous que celle-ci est indispensable. Privilégiez chaque fois que possible les méthodes
      alternatives et les produits présentant le risque le plus faible pour la santé humaine et animale et pour
      l’environnement, conformément aux principes de protection intégrée, consultez
    </span>
    <a href="http://agriculture.gouv.fr/ecophyto">http://agriculture.gouv.fr/ecophyto</a>.
  </b>
</p>
