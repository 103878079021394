import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';

import { environment } from '@env/environment';
import { LegalNoticesComponent } from '@feature/legal-notices/legal-notices.component';
import { PrivacyPolicyComponent } from '@feature/privacy-policy/privacy-policy.component';

@Component({
  selector: 'smv-anonymous-footer',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './anonymous-footer.component.html',
  styleUrls: ['./anonymous-footer.component.scss'],
})
export class AnonymousFooterComponent {
  public copyrightYear = new Date().getFullYear();
  public smvVersion = environment.version;

  constructor(private readonly dialog: MatDialog) {}

  openPrivacyDialog() {
    this.dialog.open(PrivacyPolicyComponent);
  }

  openLegalNoticesDialog() {
    this.dialog.open(LegalNoticesComponent);
  }
}
