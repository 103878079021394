import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { WidgetType } from '@core/model/application-api/widget.model';
import { AuthService } from '@core/services/auth.service';
import { WidgetService } from '@widgets/widgets.service';

export type DisplayFilter = 'FILTER_OBSERVATION' | 'FILTER_SIMULATION';

@Component({
  selector: 'smv-filter-type-data',
  standalone: true,
  imports: [CommonModule, MatExpansionModule, MatRadioModule, FormsModule],
  templateUrl: './filter-type-data.component.html',
  styleUrls: ['./filter-type-data.component.scss'],
})
export class FilterTypeDataComponent implements OnChanges {
  public title = $localize`Filtre`;
  @Input() filter: DisplayFilter = 'FILTER_OBSERVATION';
  @Input() authorizedSimulation = false;
  @Input() setTimelineForSimulation?: () => void;

  @Output() filterChange = new EventEmitter<DisplayFilter>();

  constructor(
    private authService: AuthService,
    private widgetService: WidgetService
  ) {}

  ngOnChanges() {
    this.authorizedSimulation = this.authorizedSimulation || this.authService.isSuperAdminInSync();
  }

  onFilterChange() {
    if (this.setTimelineForSimulation) {
      if (this.filter === 'FILTER_OBSERVATION') {
        this.widgetService.getWidgetContextItem(WidgetType.TIMELINE).resetToBackup();
      } else if (this.filter === 'FILTER_SIMULATION') {
        this.widgetService.getWidgetContextItem(WidgetType.TIMELINE).saveToBackup();
        this.setTimelineForSimulation();
      }
    }
    this.filterChange.emit(this.filter);
  }
}
