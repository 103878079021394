<mat-expansion-panel>
  <mat-expansion-panel-header>{{ title }}</mat-expansion-panel-header>
  <div class="vv-display-mode">
    <mat-slide-toggle [(ngModel)]="displayPoints" (change)="onDisplayModeChanged()" i18n>
      Tous les points
    </mat-slide-toggle>
    <mat-slide-toggle [(ngModel)]="displayHeat" (change)="onDisplayModeChanged()" i18n>
      La carte de chaleur
    </mat-slide-toggle>
  </div>
</mat-expansion-panel>
