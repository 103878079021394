import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BrandConsole,
  Equipment,
  Farm,
  ModelConsole,
  Section,
  SpecificationsBook,
  UserPosition,
} from '@core/model/champ-propre.model';
import { map, Observable } from 'rxjs';
import { CommonService, ListWrapper } from './common.service';

export interface FarmWrapper {
  Farm: Farm;
}

@Injectable({ providedIn: 'root' })
export class ChampPropreService extends CommonService {
  protected override readonly baseUrl: string = this.baseUrl + '/champ-propre';

  protected pipeExtractFarm(obs: Observable<FarmWrapper>): Observable<Farm> {
    return obs.pipe(
      map((result: FarmWrapper) => {
        return result.Farm;
      })
    );
  }

  constructor(protected override http: HttpClient) {
    super(http);
  }

  getPositions() {
    return this.pipeExtractList(this.http.get<ListWrapper<UserPosition>>(`${this.baseUrl}/positions`));
  }

  getBrandConsoles() {
    return this.pipeExtractList(this.http.get<ListWrapper<BrandConsole>>(`${this.baseUrl}/brand-consoles`));
  }

  getModelConsoles() {
    return this.pipeExtractList(this.http.get<ListWrapper<ModelConsole>>(`${this.baseUrl}/model-consoles`));
  }

  getEquipments() {
    return this.pipeExtractList(this.http.get<ListWrapper<Equipment>>(`${this.baseUrl}/equipments`));
  }

  getSpecificationsBooks() {
    return this.pipeExtractList(this.http.get<ListWrapper<SpecificationsBook>>(`${this.baseUrl}/specifications-books`));
  }

  getFarm(userMail: string): Observable<Farm> {
    return this.pipeExtractFarm(this.http.get<FarmWrapper>(`${this.baseUrl}/farm/${userMail}`));
  }

  isFarmInfoCompleted(farm: Farm | null): boolean {
    if (!farm) {
      return false;
    }

    const requiredProperties = ['name', 'siret', 'address', 'zipCode', 'city'] as const;

    return requiredProperties.every((property) => !!farm[property]?.trim());
  }

  isEquipmentInfoCompleted(farm: Farm | null): boolean {
    if (!farm || (farm['specificationsBook']?.label === 'Autre' && !!farm['otherSpecificationsBook']?.trim())) {
      return false;
    }

    const requiredProperties = [
      'etaPossible',
      'gpsSprayer',
      'rowSprayer',
      'brandConsole',
      'modelConsole',
      'equipment',
      'specificationsBook',
      'yearConsole',
      'sectionWidth',
    ] as const;

    return requiredProperties.every((property) => !!farm[property]);
  }

  signUp(
    firstName: string,
    lastName: string,
    mail: string,
    organisation: string,
    position: string,
    phoneNumber: string,
    password: string
  ): Observable<string> {
    const body = {
      firstName: firstName,
      lastName: lastName,
      mail: mail,
      organisation: organisation,
      position: position,
      phoneNumber: phoneNumber,
      password: password,
    };
    return this.http.post(this.baseUrl + '/user-subscription/signup', body, { responseType: 'text' });
  }

  saveFarmInfo(farm: Farm, userMail: string, section: Section): Observable<string> {
    const body = {
      name: farm.name,
      siret: farm.siret,
      address: farm.address,
      zipCode: farm.zipCode,
      city: farm.city,
      userMail: userMail,
      etaPossible: farm.etaPossible,
      gpsSprayer: farm.gpsSprayer,
      rowSprayer: farm.rowSprayer,
      brandConsole: farm.brandConsole,
      modelConsole: farm.modelConsole,
      equipment: farm.equipment,
      specificationsBook: farm.specificationsBook,
      otherSpecificationsBook: farm.specificationsBook?.label === 'Autre' ? farm.otherSpecificationsBook : '',
      yearConsole: farm.yearConsole,
      sectionWidth: farm.sectionWidth,
      section: section,
    };

    return this.http.post(this.baseUrl + '/farm/save', body, { responseType: 'text' });
  }
}
