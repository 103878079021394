<mat-card>
  <mat-card-header>
    <mat-card-title class="smv-large-card-title" i18n="Title|Farm Informations">Informations matériel</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <!-- Form Section -->
    <form [formGroup]="form">
      <!-- ETA Question -->
      <div class="mb-3">
        <div class="form-control">
          <p i18n="Label|Equipment">Avez-vous la possibilité de faire intervenir une ETA sur votre exploitation ?</p>
          <mat-radio-group formControlName="etaPossible">
            <mat-radio-button [value]="true" i18n="Button|Yes">Oui</mat-radio-button>
            <mat-radio-button [value]="false" i18n="Button|No">Non</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <!-- Specifications Book -->
      <div class="mb-3">
        <div class="form-control">
          <smv-form-field-wrapper
            formControlName="specificationsBook"
            controlType="select"
            selectIdentifier="id"
            [selectOptions]="specificationsBookOptions"
            class="form-control"
            required
          >
            <span mat-label i18n="Label|Other Specifications Book">Préciser le cahier des charges</span>
          </smv-form-field-wrapper>
        </div>
      </div>

      @if (form.controls['specificationsBook'].value?.label === 'Autre') {
        <!-- Other Specifications Book -->
        <div class="mb-3">
          <div class="form-control">
            <smv-form-field-wrapper formControlName="otherSpecificationsBook" class="form-control">
              <span i18n="Label|Other Specifications Book">Préciser le cahier des charges</span>
            </smv-form-field-wrapper>
          </div>
        </div>
      }

      <!-- Equipment Selection -->
      <div class="mb-3">
        <div class="form-control">
          <smv-form-field-wrapper
            formControlName="equipment"
            controlType="select"
            selectIdentifier="id"
            [selectOptions]="equipmentOptions"
            class="form-control"
          >
            <span mat-label i18n="Label|Equipment">Sélectionner votre matériel</span>
          </smv-form-field-wrapper>
        </div>
      </div>

      <!-- GPS Sprayer Question -->
      <div class="mb-3">
        <div class="form-control">
          <p i18n="Label|GPS Sprayer">Disposez-vous d'un pulvérisateur permettant un traitement localisé avec GPS ?</p>
          <mat-radio-group formControlName="gpsSprayer">
            <mat-radio-button [value]="true" i18n="Button|Yes">Oui</mat-radio-button>
            <mat-radio-button [value]="false" i18n="Button|No">Non</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <!-- Row Sprayer Question -->
      <div class="mb-3">
        <div class="form-control">
          <p i18n="Label|Row Sprayer">
            Votre pulvérisateur est-il équipé pour des pratiques de traitement sur le rang ?
          </p>
          <mat-radio-group formControlName="rowSprayer">
            <mat-radio-button [value]="true" i18n="Button|Yes">Oui</mat-radio-button>
            <mat-radio-button [value]="false" i18n="Button|No">Non</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <!-- Brand Console -->
      <div class="mb-3">
        <div class="form-control">
          <smv-form-field-wrapper
            formControlName="brandConsole"
            controlType="select"
            selectIdentifier="id"
            [selectOptions]="brandConsoleOptions"
            class="form-control"
          >
            <span mat-label i18n="Label|Brand Console">Marque de la Console</span>
          </smv-form-field-wrapper>
        </div>
      </div>

      <!-- Model Console -->
      <div class="mb-3">
        <div class="form-control">
          <smv-form-field-wrapper
            formControlName="modelConsole"
            controlType="select"
            selectIdentifier="id"
            [selectOptions]="modelConsoleOptions"
            class="form-control"
          >
            <span mat-label i18n="Label|Model Console">Modèle de la Console</span>
          </smv-form-field-wrapper>
        </div>
      </div>

      <!-- Year Console -->
      <div class="mb-3">
        <div class="form-control">
          <smv-form-field-wrapper formControlName="yearConsole" type="number" class="form-control">
            <span mat-label i18n="Label|Year Console">Année de la console</span>
          </smv-form-field-wrapper>
        </div>
      </div>

      <!-- Section Width -->
      <div class="mb-3">
        <div class="form-control">
          <smv-form-field-wrapper formControlName="sectionWidth" type="number" class="form-control">
            <span mat-label i18n="Label|Section Width">Largeur de tronçon (m)</span>
          </smv-form-field-wrapper>
        </div>
      </div>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <!-- Save Button -->
    <button mat-raised-button [disabled]="form.invalid" (click)="save()" color="primary" i18n="Button|Save">
      Enregistrer
    </button>
  </mat-card-actions>
</mat-card>
