import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, TitleStrategy } from '@angular/router';

import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { routes } from './app.routes';
import { matFormFieldDefaults } from '@core/configuration/material-defaults.config';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { apiMessageInterceptorFn } from '@core/interceptors/api-message.interceptor';
import { authInterceptorFn } from '@core/interceptors/auth-interceptor';
import { backendResponseInterceptorFn } from '@core/interceptors/backend-response-interceptor';
import { PageTitleStrategy } from '@core/configuration/page-title-strategy.config';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptors([apiMessageInterceptorFn, authInterceptorFn, backendResponseInterceptorFn])),
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: matFormFieldDefaults },
    { provide: TitleStrategy, useClass: PageTitleStrategy },
  ],
};
