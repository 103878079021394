import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import {
  DropdownOption,
  FormFieldWrapperComponent,
} from '@components/form/form-field-wrapper/form-field-wrapper.component';

import {
  BrandConsole,
  Dictionnary,
  Equipment,
  ModelConsole,
  Section,
  SpecificationsBook,
} from '@core/model/champ-propre.model';
import { AuthService } from '@core/services/auth.service';
import { ChampPropreService } from '@core/services/champ-propre.service';
import { NotificationService } from '@core/services/notification.service';


const modules = [
  CommonModule,
  FormFieldWrapperComponent,
  MatButtonModule,
  MatCardModule,
  MatFormFieldModule,
  ReactiveFormsModule,
  MatInputModule,
  MatSelectModule,
  MatRadioModule,
];

@Component({
  selector: 'smv-champ-propre-equipment-info',
  standalone: true,
  imports: modules,
  templateUrl: './equipment-info.component.html',
  styleUrl: './equipment-info.component.scss',
})
export class EquipmentInfoComponent {
  @Output() equipmentInfoCompleteChange: EventEmitter<boolean> = new EventEmitter();

  public form = this.formBuilder.group({
    etaPossible: [undefined as boolean | undefined],
    gpsSprayer: [undefined as boolean | undefined],
    rowSprayer: [undefined as boolean | undefined],
    brandConsole: [undefined as BrandConsole | undefined],
    modelConsole: [{ value: undefined as ModelConsole | undefined, disabled: true }],
    equipment: [undefined as Equipment | undefined],
    specificationsBook: [undefined as SpecificationsBook | undefined],
    otherSpecificationsBook: ['' as string],
    yearConsole: [undefined as number | undefined, { validators: [Validators.min(1900), Validators.max(2050)] }],
    sectionWidth: [undefined as number | undefined],
  });

  public brandConsoleOptions: DropdownOption<BrandConsole>[] = [];
  public modelConsoleOptions: DropdownOption<ModelConsole>[] = [];
  public equipmentOptions: DropdownOption<Equipment>[] = [];
  public specificationsBookOptions: DropdownOption<SpecificationsBook>[] = [];

  private allModelConsoles: DropdownOption<ModelConsole>[] = [];
  private userMail = this.authService.getUserEmailInSync() || '';

  constructor(
    private readonly champPropreService: ChampPropreService,
    private readonly notificationService: NotificationService,
    private readonly authService: AuthService,
    private formBuilder: NonNullableFormBuilder
  ) {
    this.champPropreService
      .getBrandConsoles()
      .subscribe((brandConsoles) => (this.brandConsoleOptions = this.transformOptions(brandConsoles)));
    this.champPropreService
      .getModelConsoles()
      .subscribe((modelConsoles) => (this.allModelConsoles = this.transformOptions(modelConsoles)));
    this.champPropreService
      .getEquipments()
      .subscribe((equipments) => (this.equipmentOptions = this.transformOptions(equipments)));
    this.champPropreService
      .getSpecificationsBooks()
      .subscribe(
        (specificationsBooks) => (this.specificationsBookOptions = this.transformOptions(specificationsBooks))
      );
    this.champPropreService.getFarm(this.userMail).subscribe((farm) => {
      this.form.patchValue(farm);
    });
    this.form.controls['brandConsole'].valueChanges.subscribe((selectedBrand) => {
      this.updateModelConsoleOptions(selectedBrand);
      this.form.get('modelConsole')?.enable();
    });
  }

  private transformOptions<T extends Dictionnary | ModelConsole>(options: T[]): DropdownOption<T>[] {
    return options.map((option) => ({
      label: option.label,
      value: option,
    }));
  }

  private updateModelConsoleOptions(selectedBrand: BrandConsole | undefined): void {
    if (selectedBrand) {
      this.modelConsoleOptions = this.allModelConsoles.filter(
        (model: DropdownOption<ModelConsole>) => model.value.brandConsole.id === selectedBrand.id
      );
      this.form.controls['modelConsole'].enable(); // Enable dropdown
    } else {
      this.modelConsoleOptions = [];
      this.form.controls['modelConsole'].disable(); // Disable dropdown
      this.form.controls['modelConsole'].setValue(undefined);
    }
  }

  save(): void {
    const farm = this.form.getRawValue();
    if (!this.userMail) {
      this.notificationService.error($localize`Vous n'êtes pas connecté.`);
      return;
    }
    this.champPropreService.saveFarmInfo(farm, this.userMail, Section.EQUIPMENT).subscribe({
      next: () => {
        this.notificationService.success($localize`Enregistré avec succès.`);
        this.equipmentInfoCompleteChange.emit(this.champPropreService.isEquipmentInfoCompleted(farm));
      },
      error: () => {
        this.notificationService.error($localize`Erreur lors de l'enregistrement.`);
      },
    });
  }
}
