<h2 i18n="Legal Mention">Editeur</h2>
<mat-divider></mat-divider>

<p i18n="Legal Mention">
  MAGELLIUM, Société par Actions Simplifiée au capital de 1 000 000 euros, inscrite au registre du commerce et des
  sociétés de Toulouse sous le n°450 550 991, dont le siège social se trouve 1 rue Ariane– 31520 Ramonville Saint-Agne
  (France)
</p>
<p i18n="Legal Mention">Code APE 6201Z / N°TVA : FR 83 450 550 991</p>

<p i18n="Legal Mention">Tel : +33 (0)5.62.24.70.00 (Prix d’un appel vers un poste fixe en France)</p>

<p i18n="Legal Mention">Pour nous contacter par courriel : webmaster&#64;magellium.fr</p>

<h2 i18n="Legal Mention">Directeur de publication</h2>
<mat-divider></mat-divider>

<p i18n="Legal Mention">
  FINANCES GESTION ET DEVELOPPEMENT, Société par Actions Simplifiée au capital de 1 000 049 euros, inscrite au registre
  du commerce et des sociétés de Toulouse sous le numéro 528 808 561, dont le siège social se trouve 1 rue Ariane –
  31520 Ramonville Saint-Agne (France), prise en la personne de son Président en exercice M. Pierre DUVERNEUIL,
  Présidente de la société MAGELLIUM SAS
</p>

<p i18n="Legal Mention">Tel : +33 (0)5.62.24.70.00 (Prix d’un appel vers un poste fixe en France)</p>

<p i18n="Legal Mention">Pour nous contacter par courriel : webmaster&#64;magellium.fr</p>

<h2 i18n="Legal Mention">Hébergeur</h2>
<mat-divider></mat-divider>

<p i18n="Legal Mention">OVH</p>
<p i18n="Legal Mention">2 rue Kellermann</p>
<p i18n="Legal Mention">59100 Roubaix</p>
<a href="www.ovh.fr">www.ovh.fr</a>
