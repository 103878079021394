<div class="smv-large-page-background">
  <div class="smv-large-page-title">
    <div class="page-title">
      <ng-content select="h1" />
      @if (canAccessContent()) {
        <ng-content select="[header-actions]" />
      }
    </div>
    <mat-divider />
  </div>

  <div class="page-content">
    @if (canAccessContent()) {
      <ng-content select="[page-content]" />
    } @else {
      <div class="access-denied">
        <p i18n="Access Denied">Vous n'avez pas accès à ces fonctionnalités.</p>
        <button mat-raised-button routerLink i18n="Access Denied">Retourner à l'accueil</button>
      </div>
    }
  </div>
</div>
